import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { FaImage } from 'react-icons/fa'; // Import FaImage
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';

const ImageComponent = ({ value, mainColor }: any) => {
const { intl } = useIntlContext();
    
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e: any) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {value ? (
        <div style={{ width: '100%', height: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <button
            type="button"
            onClick={handleOpenModal}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: mainColor,
              fontWeight: 'bold',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 7,
            }}
          >
            <FaImage size={20} color={mainColor} />
            {formatMessage({ id: 'imageUpload.clickToViewImage', defaultMessage: 'Click to view image' })}
          </button>
        </div>
      ) : null}

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10001,
          }}
          onClick={handleOutsideClick}
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: 'white',
              padding: '1rem',
              borderRadius: '8px',
            }}
          >
            <img
              src={typeof value === 'string' ? value : URL.createObjectURL(value[0])}
              alt="list_image"
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
            <button
              type="button"
              onClick={handleCloseModal}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <IoIosClose size={45} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageComponent;
