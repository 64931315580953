import { Link, useNavigate } from 'react-router-dom';
import Paper from '../../ui-kit/Paper/Paper';
import { paths } from '../../config/paths';
import { LuArrowLeft } from 'react-icons/lu';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useViewLibraryQuery } from '../../slices/libraryApiSlice';
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import { useSelector } from 'react-redux';

export const LibraryPage = () => {
  const {userCompany} = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext()
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: library,
    error,
    isLoading,
    refetch,
  } = useViewLibraryQuery({ id });

  useEffect(() => {
    refetch();
  }, [refetch]);




  if (isLoading) return <div>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading library...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'loading.errorLoading', defaultMessage: 'Error while loading library' })}</div>;

  const libraryData = library?.data;
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)';

  return (
    <Paper>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{paddingBottom: '15px', paddingTop: '15px'}}>
          <b>{libraryData.title}</b>
        </div>
        <div>
          <Link to={paths.library} className={'link'}>
          <p style={{color: color}}><LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'library.goBackLibrary', defaultMessage: 'BACK TO LIBRARY LIST' })} </p>
          </Link>
        </div>
      </div>
      <div style={{maxWidth: '80%'}}>
        <HtmlView content={libraryData?.content || ''}></HtmlView>
      </div>
    </Paper>
  );
};
