import React, { useEffect } from 'react';
import {
  useGetAllCompaniesQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} from '../../../../../slices/companiesApiSlice';
import { useGetCompaniesTreeQuery } from '../../../../../slices/companiesApiSlice';
import {
  ListContainer,
  ListItem,
  Button,
  Actions,
} from './CompaniesList.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuFileEdit, LuTrash, LuPlus, LuChevronDown, LuChevronRight } from 'react-icons/lu';
import { paths } from '../../../../../config/paths';

export const CompaniesList: React.FC = () => {
  const { data: companiesData, refetch } = useGetCompaniesTreeQuery({});
  const navigate = useNavigate();
  const location = useLocation();



  const [expanded, setExpanded] = React.useState<{ [key: number]: boolean }>({});

  const toggleExpand = (companyId: number) => {
    setExpanded(prev => ({ ...prev, [companyId]: !prev[companyId] }));
  };
const renderTree = (companies: any) => {
  return companies.map((company: any) => (
    <React.Fragment key={company.id}>
      <ListItem>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {company.children && company.children.length > 0 ? (
            <Button onClick={() => toggleExpand(company.id)}>
              {expanded[company.id] ? <LuChevronDown /> : <LuChevronRight />}
            </Button>
          ) : <Button disabled={true} style={{cursor: 'auto'}} >
            <LuChevronRight visibility='hidden' />
          </Button>}
          <span>{company.name}</span>
        </div>
        <Actions>
          <Button onClick={() => handleUpdate(company.id)}><LuFileEdit size={16} /></Button>
          <Button onClick={() => handleDelete(company.id)}><LuTrash size={16}></LuTrash></Button>
          <Button onClick={() => { handleCreate(company.id) }}><LuPlus size={16}></LuPlus></Button>
        </Actions>
      </ListItem>
      {expanded[company.id] && company.children && company.children.length > 0 && (
        <ul style={{ paddingLeft: '20px' }}>
          {renderTree(company.children)}
        </ul>
      )}
    </React.Fragment>
  ));
};

  const { error, isLoading } = useGetAllCompaniesQuery({});
  const [updateCompany] = useUpdateCompanyMutation();
  const [deleteCompany] = useDeleteCompanyMutation();

  useEffect(() => {
    refetch();
  }, [location.key]);

  const handleUpdate = (companyId: number) => {
    navigate(`/company/edit/${companyId}`);
  };

  const handleDelete = async (companyId: number) => {
    try {
      await deleteCompany({ id: companyId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const handleCreate = async (parentId: number) => {
    const createCompanyPath = paths.createCompany.replace(':parentId', parentId.toString());
    navigate(createCompanyPath);
    await refetch(); 
    setExpanded(prev => ({ ...prev, [parentId]: true }));
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading companies</div>;

  return (
    <ListContainer>
      <ul>
        {renderTree(companiesData?.data || [])}
      </ul>
    </ListContainer>
  );
};