import React, { useEffect, useState } from 'react';
import {
  UploadsContainer,
  UploadsTable,
  TableHeader,
  TableCell,
  DeleteButton,
  UploadForm,
  UploadInput,
} from './ProfileUploads.styles';
import { Button } from '../../../ui-kit';
import {
  useDeleteUploadMutation,
  useGetUserDocumentsQuery,
  usePostUploadMutation,
} from '../../../slices/usersApiSlices';
import { LuDelete } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';
import { formatMessage } from '../../../locale';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';

interface ProfileUploadsProps {
  type: 'certificate' | 'documents';
}

export const ProfileUploads: React.FC<ProfileUploadsProps> = ({ type }) => {
  const { intl } = useIntlContext();
  const location = useLocation();
  const { data: uploads, error, isLoading, refetch } = useGetUserDocumentsQuery({ tag: type });
  const [deleteUpload] = useDeleteUploadMutation();
  const [postUpload] = usePostUploadMutation();
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  useEffect(() => {
    refetch();
  }, [location.key]);

  const handleDelete = async (fileId: number) => {
    try {
      await deleteUpload({ id: fileId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Failed to delete file:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!file) return;
    setIsUploading(true);
    try {
      await postUpload({ data: { file: file, tag: type } }).unwrap();
      setFile(null);
      setInputKey(Date.now());
      refetch();
    } catch (error) {
      console.error('Failed to upload file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading uploads.</div>;
  
  return (
    <UploadsContainer>
      <h2>
        {type === 'documents'
          ? formatMessage({ id: 'documents.title', defaultMessage: 'Your uploaded documents' })
          : formatMessage({ id: 'certificates.title', defaultMessage: 'Your uploaded certificates' })}
      </h2>
      <UploadsTable>
        <thead>
          <tr>
            <TableHeader>
              {formatMessage({ id: 'certificates.fileName', defaultMessage: 'File Name' })}
            </TableHeader>
            <TableHeader>
              {formatMessage({ id: 'certificates.uploadDate', defaultMessage: 'Uploaded Date' })}
            </TableHeader>
            <TableHeader style={{ textAlign: 'right' }}>
              {formatMessage({ id: 'certificates.actions', defaultMessage: 'Actions' })}
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {uploads?.data?.length > 0 ? (
            uploads.data.map((file: any) => (
              <tr key={file.uuid}>
                <TableCell>
                  <a href={file?.file?.temporary_url} target="_blank" rel="noopener noreferrer">
                    {file?.file?.name}
                  </a>
                </TableCell>
                <TableCell>{new Date(file.created_at).toLocaleDateString()}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button onClick={() => handleDelete(file.uuid)}>
                    <LuDelete size={16} /> {formatMessage({ id: 'certificates.delete', defaultMessage: 'Delete' })}
                  </Button>
                </TableCell>
              </tr>
            ))
          ) : (
            <tr>
              <TableCell colSpan={3}>
                {type === 'documents'
                  ? formatMessage({ id: 'documents.noDocuments', defaultMessage: 'No documents uploaded' })
                  : formatMessage({ id: 'certificates.noCertificates', defaultMessage: 'No certificates uploaded' })}
              </TableCell>
            </tr>
          )}
        </tbody>
      </UploadsTable>
      <h2 style={{ marginTop: '20px' }}>
        {type === 'documents'
          ? formatMessage({ id: 'documents.upload', defaultMessage: 'Upload new document' })
          : formatMessage({ id: 'certificates.upload', defaultMessage: 'Upload new certificate' })}
      </h2>
      <UploadForm onSubmit={handleUpload} style={{ marginTop: '20px' }}>
        <UploadInput key={inputKey} type="file" onChange={handleFileChange} />
        <Button appearance="primary" type="submit" disabled={isUploading || !file} style={{ marginLeft: '20px' }}>
          {isUploading
            ? formatMessage({ id: 'certificates.uploading', defaultMessage: 'Uploading...' })
            : formatMessage({ id: 'certificates.uploadd', defaultMessage: 'Upload' })}
        </Button>
      </UploadForm>
    </UploadsContainer>
  );
};
