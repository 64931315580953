import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import Button from '../../../../ui-kit/Button/Button';
import { Actions, FormContent, FormHeader, FormPaper, TabContent } from './InstructionsForm.styles';
import { paths } from '../../../../config/paths';
import { Form, Formik } from 'formik';
import Row from '../../../../ui-kit/grid/Row';
import { useCreateInstructionsMutation, useUpdateInstructionsMutation, useViewInstructionsQuery } from '../../../../slices/InstructionsApiSlice';
import { useGetSubsidiariesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { FormikInput, FormikTextarea } from '../../../../ui-kit';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { handleImageData } from '../../../../slices/apiSlice';
import WangEditor from '../../../../ui-kit/Form/components/Editor/WysiwygEditor';
import {  useGetRolesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { useSelector } from 'react-redux';
           
interface InstructionsFormPageProps {
  mode: 'create' | 'edit';
}

export const InstructionsForm = ({ mode }: InstructionsFormPageProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { instructionsId } = useParams<{ instructionsId: string }>();
  const navigate = useNavigate();
  const { intl } = useIntlContext();

  const [createInstructions] = useCreateInstructionsMutation();
  const [updateInstructions] = useUpdateInstructionsMutation();

  const { data: instructions, error, isLoading, refetch } = useViewInstructionsQuery({ id: instructionsId, update: true }, { skip: mode === 'create' });
  const { data: companiesData } = useGetSubsidiariesCompaniesQuery({});


  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const { data: rolesCompaniesData} = useGetRolesCompaniesQuery(
    { id: selectedCompanies }
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem('uuids');
      localStorage.removeItem('frontId');
  };
}, []);

  const initialValues = {
    title: instructions?.data?.title || '',
    description: instructions?.data?.description || '',
    content: instructions?.data?.content || '',
    companies: instructions?.data?.companies?.map((company: any) => company.id) || [],
    released_at: instructions?.data?.released_at || '',
    card_color: instructions?.data?.card_color || userCompany.button_color ,
    cover: instructions?.data?.cover || null,
    roles: instructions?.data?.roles?.map((role: any) => role.id) || "", 
  };

  
  useEffect(() => {
    if (mode === 'edit' && instructions?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = instructions.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs));
    }
  }, [instructions?.data?.entity_files]);

  useEffect(() => {
    if (instructions?.data?.companies) {
      const initialSelectedCompanies = instructions.data.companies.map((company: any) => company.id);
      setSelectedCompanies(initialSelectedCompanies);
    }
  }, [instructions?.data?.companies]);

  useEffect(() => {
    if (mode === 'edit' && instructionsId) {
      refetch();
    }
  }, [mode, instructionsId]);

  const handleSubmit = async (values: any) => {
    try {
      // Retrieve and parse the stored values from local storage
      const backUID = localStorage.getItem('uuids');
      const frontUID = localStorage.getItem('frontId');
  
      // Base data object
      let data = {
        ...values,
        status: 'published',
      };
      if (backUID) {
        try {
          const parsedBackUID = JSON.parse(backUID);
          if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
            data = {
              ...data,
              entity_files: {
                uuid: frontUID,
                files: parsedBackUID.map((id: any) => ({
                  id,
                  tag: ""
                })),
              },
            };
          }
        } catch (error) {
          console.error('Failed to parse backUID:', error);
        }
      }
      handleImageData(values, data, 'cover');
  
      if (mode === 'create') {
        await createInstructions({ data }).unwrap();
        localStorage.removeItem('uuids');
        localStorage.removeItem('frontId');
        navigate(paths.instructions);
      } else if (mode === 'edit' && instructionsId) {
        await updateInstructions({ id: instructionsId, data }).unwrap();
        navigate(paths.instructions);
      }
    } catch (error) {
      console.error('Failed to create/update instructions:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'library.edit', defaultMessage: 'Edit:' }) + values.title
                    : formatMessage({ id: 'instructions.create', defaultMessage: 'Create Instructions' })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => {
                    navigate(paths.instructions)
                    localStorage.removeItem('uuids');
                    localStorage.removeItem('frontId');
                  }
                  }
                >
                  {formatMessage({ id: 'library.cancel', defaultMessage: 'Cancel' })}
                </Button>
                <Button appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({ id: 'library.publish', defaultMessage: 'Publish' })
                    : formatMessage({ id: 'library.save', defaultMessage: 'Save' })}
                </Button>
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <Row>
                  <FormikInput
                    label={formatMessage({ id: 'library.titleLabel', defaultMessage: 'Title' })}
                    name="title"
                    placeholder={formatMessage({ id: 'library.titlePlaceholder', defaultMessage: 'Enter a library title' })}
                    autoComplete="off"
                    required
                  />
                </Row>
                <Row>
                  <FormikTextarea
                    label={formatMessage({ id: 'library.describtionLabel', defaultMessage: 'Description' })}
                    name="description"
                    placeholder={formatMessage({ id: 'library.descriptionPlaceholder', defaultMessage: 'Enter a library description' })}
                    autoComplete="off"
                    required
                  />
                </Row>
                <Row>
                  <WangEditor name="content" />
                </Row>
                <Row>
                  <FormikInput
                    label={formatMessage({ id: 'library.cardColorLabel', defaultMessage: 'Card color' })}
                    name="card_color"
                    placeholder={formatMessage({ id: 'library.cardColorPlaceholder', defaultMessage: 'Enter a library card color' })}
                    autoComplete="off"
                    type="color"
                  />
                </Row>
                <Row>
                  <ImageUploadWrapper name="cover" type="photos" />
                </Row>
                <Row>
                  <MultiSelect
                   label={formatMessage({ id: 'library.company', defaultMessage: 'Company' })}
                    name="companies"
                    options={companiesData?.data?.map((company: any) => ({ value: company.id, label: company.name })) || []}
                    values={values.companies}
                    onChange={(field, value) => {
                    setFieldValue(field, value);
                    setSelectedCompanies(value); 
                    }}
                  />
                </Row> 
               <Row>
            <MultiSelect
            label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
            name="roles"
            options={rolesCompaniesData?.data?.map((role: any) => ({ value: role.id, label: `${role.title}` })) || null}
            values={values.roles}
            onChange={(field, value) => {
              setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? "" : value);
            }}
          />
           </Row>
           <Row>
             <FormikInput
               label={formatMessage({ id: 'library.releaseDate', defaultMessage: 'Release Date' })}
               name="released_at"
               placeholder={formatMessage({ id: 'library.releaseDatePlaceholder', defaultMessage: 'Enter a release date' })}
               autoComplete="off"
               type="date"
             />
           </Row>
              </FormContent>
            </TabContent>
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};

export default InstructionsForm;
