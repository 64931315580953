import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../locale';
import { Link, Navigate } from 'react-router-dom';
import { paths } from '../../../config/paths';
import { logout as logoutAction } from '../../../slices/authSlice';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../../slices/usersApiSlices';
import { useNavigate } from 'react-router-dom';
import useClickOutside from '../ClickOutsideToClose';
import { IoLogOutOutline } from "react-icons/io5";

const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #666;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    position: relative;
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 80%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1000;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const MenuItem = styled.div`
    padding: 10px 20px;
    display: block;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: #f1f1f1;
    }
`;

interface ProfileCircleProps {
  name: string;
}

const getInitials = (name: string): string => {
  const names = name.split(' ');
  const initials = names.map((n) => n.charAt(0).toUpperCase()).join('');
  return initials;
};

const ProfileCircle: React.FC<ProfileCircleProps> = ({ name }) => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [logout] = useLogoutMutation();

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await logout({}).unwrap();
      dispatch(logoutAction());
      window.dispatchEvent(new Event('userLoggedOut'));
      navigate(paths.root);
      window.location.reload();  
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div ref={dropdownRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Link to={paths.profile} onClick={() => setIsOpen(false)} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
        <Circle>
          {getInitials(name)}
        </Circle>
        <p style={{ fontWeight: '500', fontSize: 14}}>{name}</p>
      </Link>
      <button onClick={handleLogout} style={{ fontSize: 14, fontWeight: '400', color: 'red', background: 'none', border: 'none', cursor: 'pointer', flexDirection: 'row', display: 'flex', gap: 5, alignItems: 'center', borderLeft: '1px solid #f1f1f1', padding: '0 10px' }}>
        {formatMessage({ id: 'profileCircle.logout', defaultMessage: 'Logout' })}
        <IoLogOutOutline size={20} /> 
      </button>
    </div>
  );
};

export default ProfileCircle;