import styled from 'styled-components';
import { css, SerializedStyles } from '@emotion/react';
import { omit } from 'lodash';


export const Input = styled.input<{ color?: string }>`      
    width: 100%;
    padding: 0.5rem 0.5rem;
    border: 1px solid #00000017;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 16px;

    &:hover {
        border-color: ${({ color }) => color};
    }
    &:focus {
        border-color: ${({ color }) => color};
        outline: none;
    }
    &:active {
        border-color: ${({ color }) => color};
    }
`

export const Button = styled.button<{ color?: string }>`
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    background-color: ${({ color }) => color};
    color: white;
    &:hover {
        filter: brightness(0.85);
    }
`
