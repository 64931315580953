import styled from 'styled-components';

export const FormPaper = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:1.8rem;
    margin-bottom: 20px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;

export const TabContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9997;
`;
export const ColumnTopLevel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9998;
`;
export const ColumnTopLevel2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9999;
`;

export const TaskTypeContainer = styled.div`
    text-align: left;
    margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: bold;
  color: black;
  display: inline-block;
`
export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    input {
        width: 100%;
        padding: 0.5rem;
    }
`;

export const TextArea = styled.input<{ color?: string }>`
    padding: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    margin-bottom: 1rem;
    resize: none;
    border-radius: 13px;

    &:hover {
        border: 1px solid ${({ color }) => color};
    }

    &:focus {
    outline: none !important;
    border: 1px solid ${({ color }) => color};
    }

`
export const TextAreaDescription = styled.textarea`
    min-height: 120px;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
    resize: none;
    border-radius: 13px;

    &:focus {
        outline: none !important;
        border: 2px solid #719ECE;
    }
`


export const LabelField = styled.label`
  margin-top: 10px;
  margin-bottom: 0.5rem;
  display: inline-block;
  color: black;
  font-weight: bold;
`

export const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    input[type='checkbox'] {
        transform: scale(1.2);
    }

    label {
        font-size: 1rem;
    }
`;

