import styled from 'styled-components';
import Paper from '../../ui-kit/Paper/Paper'
import { Link } from 'react-router-dom'

export const Container = styled(Paper)`
    position: relative;
    padding: 20px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: transparent;
`;

export const HeaderDivInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        flex-direction: column;
        justify-content: center;
    }
`;
export const HeaderDivInfoContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        flex-direction: column;
        justify-content: center;
    }
`;

 export const HideOnMobile = styled.span`
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none;
    }
`;

export const Title = styled.h2`
    margin: 0;
    break-word: break-all;
`;

export const BackLink = styled(Link)`
    display: flex;
    align-self: flex-start;
    align-items: center;
    color: ${(props) => props.color};
    gap: 2px;
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        font-size: 12px;
    }
`;

export const BackButton = styled.button`
    display: flex;
    align-self: flex-start;
    align-items: center;
    color: ${(props) => props.color};
    gap: 2px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        font-size: 12px;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const TaskContent = styled.p`
    width: 50%;
`;

export const TaskType = styled.p`
    color: #13679e;
    font-weight: bold;
    background-color: #b3dcf6;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 8px;
`;


export const InfoSection = styled.div`
    margin: 20px;
    padding-right: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    background-color: transparent;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 2px;
    `;

export const UserAvatar = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #13679e;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserInitials = styled.p`
    color: white;
    font-weight: 500;
    font-size: 18px;
`;

export const UserAvatarInfo = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #13679e;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserInitialsInfo = styled.p`
    color: white;
    font-weight: 500;
    font-size: 14px;
`;

export const UserName = styled.p`
    padding: 5px;
    color: black;
    font-weight: bold;
`;
export const UserNameInfo = styled.p`
    padding: 5px;
    color: grey;
`;

export const Status = styled.p`
    font-weight: bold;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 18px;
    text-transform: uppercase;

`;

export const Dates = styled.div`
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: grey;
    font-size: 12px;
`;

export const ContainerTask = styled.div`
    border-bottom: none;
    width: 80%;
    border-radius: 15px;
    text-align: left;
    margin-top: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);

`;

export const HeaderTask = styled.h2`
  background-COLOR: #f5f5f5;
  border-radius: 25px 25px 0 0;
  color: grey;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightGrey;
`;

export const ContainerTaskIsInput = styled.div`
    border-bottom: none;
    width: 80%;
    border-radius: 15px;
    text-align: left;
    margin-top: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
`;

export const RowIsInput = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    fled-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    fled-wrap: wrap;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightGrey;
    overflow-y: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    align-items: center;
    boxShadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;

export const Row2 = styled.div<{ $breakpoint?: any; $columns?: number }>`
  width: 100%;
  flex: 1;
  display: grid;
  gap: 1.5rem;

  ${({ $breakpoint, theme }) => `
    @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `}
`
export const Label = styled.p`
  flex: 1;
  font-weight: bold;
  color: grey;
`;

export const FieldType = styled.p`
  flex: 1;
  color: grey;
`;

export const FieldValue = styled.p`
  flex: 1;
`;
export const EditableField = styled.input<{ color?: string }>`
    width: 300px;
    border-radius: 8px;
    border: 1px solid lightGrey;
    padding: 10px;
    &:hover {
        border-color: ${({ color }) => color};
    }

    &:focus {
        border: 1px solid ${({ color }) => color};
        outline: none;
    }
`;

export const IconButton = styled.button`
  background: transparent;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

export const CustomSelectMultiContainer = styled.div`
    .custom-select-multi {
        z-index: 9999;
    }
`;

 export const TabsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

 export const TabButton = styled.button<{ active?: boolean }>`
    padding: 10px 20px;
    border: none;
    background-color: ${({ active }) => (active ? '#007bff' : '#f1f1f1')};
    color: ${({ active }) => (active ? 'white' : 'black')};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover:not([disabled]) {
        background-color: ${({ active }) => (active ? '#0056b3' : '#ddd')};
    }
`;

 export const TabContent = styled.div`
    margin-top: 20px;
`;


export const ButtonTabs = styled.button<{ active: boolean, color: any }>`
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'rgba(141, 141, 141, 1)')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  font-weight: 400;
  height: 36px;
`;

export const ButtonFilter = styled.button<{ color: any, active?: boolean }>`
  display: flex;
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding: 7px 25px;
`;

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    input {
        width: 100%;
        padding: 0.5rem;
    }
`;

 export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

 export const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

 export const Tbody = styled.tbody`
    background-color: #fff;
`

 export const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`

 export const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

export const Td = styled.td`
  vertical-align: middle;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`
export const ScrollToTopButton = styled.div<{ color: any }>`
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    z-index: 1000;
`

export const StatusBottom = styled.div`
position: fixed;
bottom: 5px;
right: 55px;
cursor: pointer;
`
