import { Errors } from './Errors'

export const sv = {
  Error: {
    ...Errors,
  },
  ...{
    menu: {
      company: 'Företag',
      handbook: 'Handbok',
      courses: 'Kurser',
      library: 'Bibliotek',
      logout: 'Logga ut',
    },
    loginPage: {
      login: 'Logga in',
      loginMicrosoft: 'Logga in med Microsoft',
      recoverPassword: 'Återställ lösenord',
      loginEmailPassword: 'E-post & Lösenord',
      email: 'E-post',
      yourEmail: 'Din e-post',
      password: 'Lösenord',
      yourPassword: 'Ditt lösenord',
      loading: 'Laddar...',
      forgotPassword: 'Glömt lösenord?',
      sendRecoveryEmail: 'Skicka återställningsmail',
      sending: 'Skickar...',
      backToLogin: 'Tillbaka till inloggning',
    },

    side_menu: {
      Activities: 'Aktiviteter',
      Organization: 'Organisation',
      virtualAssistant: 'AI Assistent',
      instructions: 'Instruktioner för plattformen',
      Support: 'Support',
      Archive: 'Arkiv',
      actionsPlan: 'Åtgärder',
      claims: 'Rapportering',
      investigations: 'Riskbedömning',
      dashboard: 'Dashboard',
      annualPlan: 'Årsplan',
      profile: 'Profil',
      users: 'Användare',
      lessons: 'Lektioner',
      task: 'Uppgifter',
    },

    modal: {
      title: 'De följande kommer att skapas vill du fortsätta?',
      empty: 'Inget att uppdatera eller skapa', 
      tryAgain: 'Följande är fel försök igen!',
      creating: 'Skapar följande från rad: ',
      incorrect: 'Följande är felaktigt från rad: ',
      isCreated: 'Uppladdningen lyckades!',
      isFailed: 'Uppladdning misslyckades!',
      serverError: 'Internt serverfel uppstod!',
      confirmSuccess: 'Ändringar från rad: ',
      confirmError: 'Fel från rad: ',
    },

    readMore: 'Läs mer »',
    confirm: 'Bekräfta',
    cancel: 'Avbryt',
    ok: 'OK',
    search: 'Sök...',
    next: 'Nästa',
    previous: 'Föregående',

    annualPlan: {
      uploadAnnualPlan: 'Ladda upp Årsplan',
      upload: 'Ladda upp',
      cancel: 'Avbryt',
    },

    taskType: {
      edit: 'Redigera: ',
      create: 'Skapa Uppgiftstyp',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      name: 'Namn',
      description: 'Beskrivning',
      placeholderTaskTypeTitle: 'Ange en uppgiftstyp titel',
      placeholderTaskTypeDescription: 'Ange en uppgiftstyp beskrivning',
      addCustomField: 'Lägg till anpassat fält',
      typeField: 'Fälttyp',
      isInput: 'Är Indata',
      isOutput: 'Är Utdata',
      isMulti: 'Är Multival',
      isRequired: 'Är Obligatorisk',
      actions: 'Åtgärder',
      fieldType: 'Fälttyp',
      selectType: 'Välj en uppgiftstyp...',
      customFieldName: 'Anpassat fältnamn',
      placeholderCustomFieldName: 'Ange en uppgiftstyp namn',
      options: 'Alternativ',
      placeholderOptions: 'Ange alternativ',
      createCustomField: 'Skapa anpassat fält',
      editCustomField: 'Redigera',
      createNewTaskType: 'Skapa ny uppgiftstyp',
      createdAt: 'Skapad den: ',
      updatedAt: 'Uppdaterad den: ',
      to: 'Till ',
      wasSuccessfullyCopied: ' kopiering slutförd.',
      encounteredErrorDuringCopy: ' stötte på ett fel under kopiering - ',
      optionsList: 'Alternativlista',
      addOption: 'Lägg till alternativ',
      createOption: 'Skapa alternativ',
      editOption: 'Redigera alternativ',
      editList: 'Redigera lista',
      createList: 'Skapa lista',
      draftSentence: `Uppgifttyp typen kommer skapas som utkast,
       är du säker att du vill försätta?`,
      draft: 'UTKAST',
      createAsDraft: 'Skapa som utkast',
      saveAsDraft: 'Spara & utkast',
      savePublish: 'Spara & publicera',
      noTaskTypes: 'Inga uppgiftstyper tillgängliga',
      sortByA: 'Sortera A till Ö',
      sortByZ: 'Sortera Ö till A',
      is_simplified: 'Är förenklad uppgiftstyp',
      dropdown: 'Rullgardinsmeny',
      user: 'Användare',
      department: 'Avdelning',
      office: 'Kontor',
      jobTitle: 'Jobbtitel',
      groupProject: 'Projektgrupp',
      date: 'Datum',
      string: 'Sträng',
      number: 'Nummer',
      editor: 'Redigerare',
      list: 'Lista',
      image: 'Bild',
    },

    toolTip: {
      edit: 'Redigera',
      copy: 'Duplicera',
      copySubsidiary: 'Kopera till Dotterbolag',
      start: 'Starta',
      delete: 'Radera',
    },

    confirmModal: {
      noticeCourse: 'Är du säker på att du vill radera denna kurs?',
      noticeLesson: 'Är du säker på att du vill radera denna lektion?',
    },

    index: {
      name: 'Arbetsmiljöportalen',
      content: 
      `Välkommen till Arbetsmiljöportalen, här du hittar allt arbetsmiljöarbete samlat på ett ställe.
      I portalen finns policys, rutiner, mallar och utbildningsmaterial för att nämna ett par exempel.
      Arbetsmiljöarbetet är viktigt ut flera aspekter. Arbetsmiljö påverkar varje person som arbetar på
      arbetsplatsen samt förutsättningarna för organisationen att nå mål och visioner.
      Även om arbetsmiljöarbetet i mångt och mycket bygger på lagar och regler finns där en ovärderlig
      kraft och potential att bygga vidare på. Genom att arbeta med arbetsmiljön på olika sätt och vidta
      åtgärder visar organisationen också att medarbetarnas trivsel och säkerhet tas på allvar.
      Arbetsmiljöarbetet bidrar kort och gott till medarbetarnas välbefinnande och en hållbar prestation
      över tid. Tack för ditt engagemang och bidrag till organisationens arbetsmiljöarbete!`,
      button: 'Träning och aktiviteter',
    },

    dashboard: {
      myCourses: 'Mina kurser',
      courseStatistics: 'Kursstatistik',
      performanceStatistics: 'Kursresultat',
      completed: 'Avslutade',
      inProgress: 'Pågående',
      toBegin: 'Att starta',
      completedSuccessfully: 'Klarade godkända',
      completedUnsuccessfully: 'Klarade icke godkända',
      fieldName: 'Fältnamn',
      fieldValue: 'Fältvärde',
      risks: 'Handlingsplan',
      noRisk: 'Välj risk i dashboard inställningar för att se riskerna.',
      title: 'Titel',
      creator: 'Tilldelad',
      createdDate: 'Skapelsedatum',
      exportToExel: 'Exportera till Excel',
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      resetDate: 'Återställ datum',
    },

    dashboardSettings: {
      title: 'Risker Dashboard',
      taskType: 'Uppgiftstyp',
      selectType: 'Välj en uppgiftstyp...',
      customFields: 'Anpassade fält',
      selectField: 'Välj ett anpassat fält...',
      save: 'Spara',
    },

    courseCard: {
      continue: 'FORTSÄTT',
      start: 'STARTA',
      quiz: 'STARTA QUIZ',
      review: 'GRANSKA',
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      lessons: 'Lektioner',
      attempts: 'Försök',
      completed: 'SLUTFÖRD!',
      failed: 'MISSLYCKAD!',
    },

    filters: {
      withExpiry: 'Kurser med slutdatum',
      withoutExpiry: 'Kurser utan slutdatum',
      quickFilter: 'Snabbfilter',
    },

    task: {
      newTask: 'Ny uppgift',
      assigne: 'Tilldelad: ',
      taskType: 'Uppgiftstyp',
      dueDate: 'Förfallodatum',
      updateAt: 'Uppdaterad den: ',
      createAT: 'Skapad den: ',
      completedDate: 'Slutförd den: ',
      title: 'Titel',
      titlePlaceholder: 'Ange titel för uyppgift',
      assignTo: 'Tilldela till',
      assignToPlaceholder: 'Välj användare...',
      create: 'Skapa Uppgift',
      edit: 'Redigera: ',
      cancel: 'Avbryt',
      save: 'Spara',
      nextStep: 'NÄSTA STEG',
      previousStep: 'FÖREGÅENDE STEG',
      customField: 'Anpassat fält',
      fieldType: 'Fälttyp',
      fieldName: 'Fältnamn',
      fieldValue: 'Fältvärde',
      backTo: 'TILLBAKA TILL UPPGIFTER',
      creator: 'Skapad av: ',
      select: 'Välj ',
      enter: 'Ange ',
      saveFile: 'Sparad!',
      copyToCompany: "Kopiera uppgifter till annat företag",
      workLog: 'Arbetslogg',
      history: 'Historik',
      has: 'har',
      getTask: 'Hämta alla uppgifter',
      is_archived: 'Är arkiverad',
      is_overdue: 'Är förfallen',
      yes: 'Ja',
      no: 'Nej',
      saved: 'Sparad!',
      seeMore: 'Se mer',
      seeLess: 'Se mindre',
      overdue: 'FÖRFALLEN',
      archived: 'ARKIVERAD',
      active: 'AKTIV',
      stage: 'Steg',
      assign: 'Tilldelad',
      all: 'ALLA',
      personal: 'PERSONLIG',
      filterByStage: 'Filtrera efter steg...',
      noCourses: 'Inga kurser tillgängliga',
      noTask: 'Inga uppgifter tillgängliga',
      taskList: 'Uppgiftslista',
      assending: 'Närmaste',
      descending: 'Senaste',
      noDueDate: 'Ingen förfallodatum',
      noDataFilledIn: 'Ingen data ifylld',
      scrollToTop: 'Scrolla till toppen',
      taskName: 'Uppgift',
      advanceView: 'Avancerad Uppgift vy ',
    },

    handbook: {
      searchPlaceholder: 'Sök',
      newHandbookButton: 'Ny sida',
      goBackHandbook: 'TILLBAKA TILL HANDBOKEN',
    },

    clientCourses: {
      backToCourseList: 'Gå tillbaka till kurslista',
      publishedBy: 'Publicerad av',
      publicationDate: 'Publiceringsdatum',
      lessons: 'Lektioner',
      completed: 'Slutförd',
      goToLesson: 'Gå till lektion',
      startQuiz: 'Starta quiz',
    },

    handbookForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange rubrik',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange en kort beskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange innehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Ange kortfärg',
      departmentLabel: 'Avdelning/Enhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Projektgrupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    profileCircle: {
      profile: 'Profil',
      logout: 'Logga ut',
    },

    department: {
      noDepartment: 'Ingen överordnad avdelning',
      createTitle: 'Skapa avdelning',
      editTitle: 'Redigera avdelning',
      nameLabel: 'Avdelningsnamn *',
      parentLabel: 'Överordnad avdelning',
      companyLabel: 'Företag',
      headLabel: 'Chef för Affärsenhet / Team',
      errorLoadingUsers: 'Fel vid laddning av användare',
      errorLoadingDepartments: 'Fel vid laddning av avdelningar',
      departmentLabel: 'Avdelning/Enhet/Team',
    },

    company2: {
      companiesTab: 'Företag',
      structureTab: 'Företagsstruktur',
      officesTab: 'Kontor',
      jobTitlesTab: 'Jobbtitel',
      projectGroupsTab: 'Projektgrupp',
      taskTypes: 'Uppgiftstyper',
      usersImportTab: 'Importera användare',
      dahboardSettings: 'Dashboard inställningar',
      newCompanyButton: 'Nytt företag',
    },

    companyForm: {
      edit: 'Redigera: ',
      create: 'Skapa företag',
      cancel: 'Avbryt',
      save: 'Spara',
      nameLabel: 'Företagsnamn',
      namePlaceholder: 'Ange företagsnamn',
      parentCompanyLabel: 'Moderföretag',
      parentCompanyPlaceholder: 'Inget moderföretag',
      orgNumberLabel: 'Organisationsnummer',
      orgNumberPlaceholder: 'Ange organisationsnummer',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Ange e-postadress',
      phoneLabel: 'Telefon',
      phonePlaceholder: 'Ange telefonnummer',
      addressLabel: 'Adress',
      addressPlaceholder: 'Ange adress',
      cardColorLabel: 'Huvudfärg',
      buttonColorLabel2: 'Knappfärg',
    },

    office: {
      createTitle: 'Skapa kontor',
      editTitle: 'Redigera kontor',
      nameLabel: 'Kontorsnamn',
      newOfficeButton: 'Nytt kontor',
      errorLoadingOffices: 'Fel vid laddning av kontor',
    },

    jobTitle: {
      createTitle: 'Skapa jobbtitel',
      editTitle: 'Redigera jobbtitel',
      nameLabel: 'Jobbtitel',
      newJobTitleButton: 'Ny jobbtitel',
      errorLoadingJobTitles: 'Fel vid laddning av jobbtitlar',
    },

    groupProject: {
      createTitle: 'Skapa projektgrupp ',
      editTitle: 'Redigera projektgrupp',
      nameLabel: 'Namn projektgrupp',
      newGroupButton: 'Ny projektgrupp',
      errorLoadingGroups: 'Fel vid laddning av projektgrupper',
    },

    loading: {
      loading: 'Laddar...',
      errorLoadingQuiz: 'Fel vid laddning quiz',
      errorLoading: 'Fel vid laddning biliotek',
      errorLoadingHandbook: 'Fel vid laddning handbok',
      errorLoadingCourses: 'Fel vid laddning kurser',
      errorLoadingInvestigation: 'Fel vid laddning riskbedömning',
      errorLoadingActionsPlan: 'Fel vid laddning återgärder',
      errorLoadingClaims: 'Fel vid laddning rapportering',
      errorLoadingVirtualAssistant: 'Fel vid laddning AI Assistent',
      errorLoadingInstructions: 'Fel vid laddning instruktioner',
      errorLoadingSupport: 'Fel vid laddning support',
      errorLoadingDashboard: 'Fel vid laddning Dashboard',
      errorLoadingUsers: 'Fel vid ladddning användare',
      errorLoadingCompany: 'Fel vid laddning företag',
    },

    chooseHere: 'Välj här',
    create: 'Skapa',
    save: 'Spara',
    company: 'Företag',

    userRole: {
      super_admin: 'Super Admin',
      admin: 'Administratör',
      power_user: 'Power User',
      course_manager: 'Kursansvarig',
      manager: 'Chef',
      content_manager: 'Innehållsansvarig',
      team_leader: 'Teamledare',
      user: 'Användare',
    },

    userStatus: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
    },

    userList: {
      name: 'Namn',
      email: 'E-post',
      role: 'Roll',
      status: 'Status',
      action: 'Åtgärder',
      deleteConfirmation: 'Är du säker på att du vill radera användaren?',
      edit: 'Redigera',
      deactivate: 'Deaktivera',
      activate: 'Aktivera',
      delete: 'Radera',
    },
    
    imageUploader: {
      chooseFile: 'Välj fil',
      choosePhoto: 'Välj foto',
      drag: 'Eller dra och släpp ',
      here: 'här',
      photo: 'foto',
      document: 'dokument',
    },

    profile: {
      buttonProfile: 'Profil',
      buttonCertificates: 'Certifikat',
      buttonDocuments: 'Dokument',
    },

    courses: {
      title: 'Kurser',
      manageCourses: 'Hantera kurser',
      newCourseButton: 'Ny kurs',
      titleLabel: 'Titel',
      statusLabel: 'Status',
      startedLabel: 'Startad',
      expiredLabel: 'Stängd',
      actionsLabel: 'Åtgärder',
      returnCourse: 'Gå tillbaka till kurs',
      completeLesson: 'Avsluta lektion',
      copyToCompany: 'Kopiera kurs till annat företag',
      chooseLabel: 'Välj företag',
      multiSelectLabel: 'Välj företag',
      copy: 'Kopiera',
    },

    createEditCourse: {
      title: 'Skapa kurs',
      titleLabel: 'Titel',
      decsLabel: 'Beskrivning',
      subComponyLabel: 'Dotterbolag',
      publish: 'Publicera',
      cancel: 'Avbryt',
      save: 'Spara',
      create: 'Skapa',
      courseDetails: 'Kursdetaljer',
      lessons: 'Lektioner',
      quiz: 'Quiz',
      participants: 'Deltagare',
      schedule: 'Schema',
      courseAlert: 'Fyll i alla fält för att publicera',
      copy: 'Kopiera & Spara',
    },

    lessons: {
      pageTitle: 'Lektioner',
      title: 'Titel',
      actions: 'Åtgärder',
      titlePlaceholder: 'Ange lektionstitel',
      addLesson: 'Lägg till lektion',
      newLesson: 'Ny lektion',
      emptyLesson: 'Inga skapade lektioner. Skapa ny lektion för att försätta',
      cancel: 'Avbryt',
      back: 'Tillbaka',
      edit: 'Redigera',
      view: 'Visa',
      save: 'Spara',
      editLesson: 'Redigera lektion',
      createLesson: 'Skapa ny lektion',
      viewOnlyCourse: 'Välj lektion till vänster för förhandsvisning',
      attachLesson: 'Bifoga lektion',
      chooseLabel: 'Välj en lektion',
      add: 'Lägg till',
      chooseHere: 'Välj här',
    },

    quiz: {
      title: 'Quiz detaljer',
      noQustioons: 'Finns inga skapade frågor. Skapa nya för att fortsätta',
      newQuestion: 'Ny fråga',
      addQuestion: 'Lägg till fråga',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en quiztitel',
      passLabel: 'Procent för att passera',
      passPlaceholder: 'Ange procent för att passera',
      attemptLabel: 'Försök',
      attemptPlaceholder: 'Ange antal försök',
      create: 'Skapa',
      save: 'Spara',
      editQestion: 'Redigera fråga',
      createQestion: 'Skapa fråga',
      qestionLabel: 'Fråga',
      qestionPlaceholder: 'Skriv fråga här',
      correct: 'Korrekt',
      choice: 'Val',
      actions: 'Åtgärder',
      questions: 'Val',
      newChoice: 'Nytt val',
      returnCourse: 'Gå tillbaka till kurs',
      yourScore: 'Dina poäng',
      isPassed: 'Grattis! Du klarade quizet',
      failed: 'Tyvärr, du klarade inte quizet',
      question: 'Fråga',
      needScore: 'Du behöver',
      toPass: 'för att bli godkänd på kursen. Lycka till!',
      selectAnswears: 'Vänligen välj rätt svar:',
      prvsQuestion: 'Föregående fråga',
      nextQuestion: 'Nästa fråga',
      sendQuiz: 'Skicka quiz',
      choicePlaceholder: 'Skriv in val här',
    },

    participants: {
      title: 'Redigera deltagare',
      department: 'Avdelning/Affärsenhet/Team',
      office: 'Kontor',
      jobTitle: 'Jobbtitel',
      groupProject: 'Projektgrupp',
      user: 'Användare',
      isMandatory: 'Obligatorisk',
      save: 'Spara ändringar',
      users: 'Alla användare',
    },

    schedule: {
      title: 'Redigera schema',
      startedAt: 'Startdatum *',
      expiredAt: 'Slutdatum',
      saveChanges: 'Spara ändringar',
      noEndDate: 'Ingen slutdatum'
    },

    library: {
      searchPlaceholder: 'Sök',
      newLibraryButton: 'Ny sida i biblioteket',
      title: 'Skapa sida i biblioteket',
      edit: 'Redigera: ',
      cancel: 'Avbryt',
      publish: 'Publisera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange titel för sida i biblioteket',
      describtionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange beskrivning för sida i biblioteket',
      cardColorLabel: 'Kort färg',
      cardColorPlaceholder: 'Välj färg till kort för biblioteket',
      company: 'Företag',
      releaseDate: 'Publiceringsdatum',
      userRoll: 'Användarroller',
      goBackLibrary: 'TILLBAKA TILL BIBLIOTEK LISTA'
    },

    employes: {
      getTamplateTitle: 'Hämta mall för användare',
      getTemplate: 'Hämta mall',
      upload: 'Ladda upp nya användare',
      uploading: 'Laddar upp...',
      uploadd: 'Ladda upp fil'
    },

    certificates: {
      title: 'Dina uppladdade certifikater',
      fileName: 'Filnamn',
      uploadDate: 'Uppladdningsdatum',
      actions: 'Åtgärder',
      delete: 'Radera',
      noCertificates: 'Inga certifikat uppladdade',
      upload: 'Ladda upp nytt certifikat ',
      uploading: 'Laddar upp...',
      uploadd: 'Ladda upp fil'
    },

    documents: {
      title: 'Dina uppladdade dokumenter',
      noDocuments: 'Inga dokument uppladdade',
      upload: 'Ladda upp nytt dokument',
    },

    investigation: {
      searchPlaceholder: 'Sök',
      newInvestigationButton: 'Ny sida',
      goBackToInvestigation: 'TILLBAKA TILL RISKBEDÖMNING LISTA',
    },

    investigationForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en titel',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange en beskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange innehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Välj kortfärg',
      departmentLabel: 'Avdelning/Affärsenhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Grupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    claim: {
      searchPlaceholder: 'Sök',
      newClaimButton: 'Ny sida',
      goBackToClaim: 'TILLBAKA TILL RAPPORTERING LISTA',
    },

    claimForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en titel',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange en beskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange innehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Ange en kortfärg',
      departmentLabel: 'Avdelning/Affärsenhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Grupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    actionsPlan: {
      goBackToActionsPlan: 'TILLBAKA TILL ÅTGÄRDER LISTA',
    },

    virtualAssistant: {
      create: 'Skapa AI Assistent',
      goBackToVirtualAssistant: 'TILLBAKA TILL AI ASSISTENT LISTA',
    },

    instructions: {
      create: 'Skapa ny instruktion',
      goBackToInstructions: 'TILLBAKA TILL INSTRUKTIONER LISTA',
    },

    support: {
      create: 'Skapa Support',
      goBackToSupport: 'TILLBAKA TILL SUPPORT ',
    },
    
    imageUpload: {
      choosePhoto: 'Välj bild',
      clickToViewImage: 'Klicka för att visa bilden',
    },

    hero: {
      description:
        'Arbetsmiljöarbete skapar förutsättningar för välbefinnande, engagemang och prestation. För ett effektivt arbetsmiljöarbete är ett systematiskt arbetssätt avgörande. I grunden innebär det att kontinuerligt genomföra utredningar, riskbedömningar, implementering av åtgärder samt utvärdera vidtagna åtgärder. Kort och gott så handlar ett systematiskt arbetsmiljöarbete om att skapa en utvecklade, säker och hållbar arbetsplats för organisation och medarbetare.',
      header: 'Välkommen till ',
      subHeaderHandbook: 'Här hittar du Handbok.',
      subHeaderCourses: 'Här hittar du Kurser.',
      subHeaderLibrary: 'Här hittar du Bibliotek.',
      subHeaderInvestigations: 'Här hittar du Riskbedömning.',
      subHeaderActionsPlan: 'Här hittar du Åtgärder.',
      subHeaderClaims: 'Här hittar du Rapportering.',
      subHeaderVirtualAssistant: 'Här hittar du AI Assistent .',
      subHeaderInstructions: 'Här hittar du Instruktioner.',
      subHeaderSupport: 'Här hittar du Support.',
      subHeaderDashboard: 'Här hittar du Dashboard.',
      subHeaderTasks: 'Här hittar du Uppgifter.',
      header_22: ', Välkommen till ',
      name: 'Arbetsmiljöportalen',
    },
    
    errorLoadingUsers: 'Fel vid laddning av användare',

    users: {
      searchPlaceholder: 'Sök',
      newUserButton: 'Ny användare',
      edit: 'Redigera användare',
      create: 'Skapa användare',
      cancel: 'Avbryt',
      save: 'Spara',
      firstname: 'Förnamn',
      firstnamePlaceholder: 'Skriv här',
      lastname: 'Efternamn',
      lastnamePlaceholder: 'Skriv här',
      username: 'Användarnamn',
      usernamePlaceholder: 'Skriv här',
      email: 'E-postadress',
      emailPlaceholder: 'Skriv här',
      locale: 'Språk',
      localePlaceholder: 'Välj här',
      role: 'Roll',
      rolePlaceholder: 'Välj här',
      department: 'Avdelning',
      departmentPlaceholder: 'Välj här',
      office: 'Kontor',
      officePlaceholder: 'Välj här',
      collar: {
        blue: 'Arbetare',
        white: 'Tjänsteman',
      },
      roleDescriptions: {
        super_admin: 'Super Admin',
        admin: 'Administratör',
        power_user: 'Power User',
        course_manager: 'Kursansvarig',
        manager: 'Chef',
        content_manager: 'Innehållsansvarig',
        team_leader: 'Teamledare',
        user: 'Användare',
      },
    },
  },
}

export default sv
