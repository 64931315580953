import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllClaimsQuery,
  useUpdateClaimMutation,
  useDeleteClaimMutation,
} from '../../../slices/claimsApiSlice';
import { CardGrid } from '../../../ui-kit/Card/Card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import { formatMessage } from '../../../locale';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';

interface ClaimsListProps {}

export const ClaimsList: React.FC<ClaimsListProps> = () => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [updateClaim] = useUpdateClaimMutation();
  const [deleteClaim] = useDeleteClaimMutation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={useGetAllClaimsQuery} currentPage={currentPage}>
      {(claims, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingClaims', defaultMessage: 'Error while loading Claim' })}</p></div>

        const handleUpdate = (id: number) => {
          navigate(`/claim/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteClaim({ id }).unwrap();
            refetch();
          } catch (error) {
            console.error('Error deleting claim:', error);
          }
        };

        const cardsData = claims.map((claim: any) => ({
          id: claim.id,
          canEdit: canDo('claim: update'),
          title: claim.title,
          bgColor: claim.card_color,
          description: claim.description,
          cover: claim.cover,
          viewLink: `/claim/${claim.id}`,
          handleUpdate,
          handleDelete,
        }));

        return <CardGrid cards={cardsData} />;
      }}
    </PaginableList>
  );
};

export default ClaimsList;
