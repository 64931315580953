import React, { ReactNode, Fragment, HTMLAttributes, useState } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import {
  HeaderContainer,
  HeaderWrapper,
  Label,
  LinkItem,
  Navigation,
  PreContainer,
  HamburgerIcon,
  MobileMenu,
  MobileMenuWrapper,
  Logo,
  HideOnDesktop,
  HideOnMobile,
  StyledLink, 
  StyledButton
} from './Header.styles';
import LanguageSwitcher from './LanguageSwitcher';
import LanguageSwitcherMobile from './MobileComponents/LanguageSwitcherMobile';
import { formatMessage } from '../../locale';
import ProfileCircle from './ProfileCircle';
import ProfileCircleMobile from './MobileComponents/ProfileCircleMobile';
import CompanySelector from './CompanySelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ASIDE_NAV } from '../../constants/asideNav';
import { HeaderPermission } from '../../enum/Header/HeaderPermission';
import {
  LuAlbum,
  LuAreaChart,
  LuBarChart,
  LuBell,
  LuBookOpenCheck,
  LuHeart,
  LuHelpCircle,
  LuSearch,
  LuUser2,
  LuUsers2,
} from 'react-icons/lu'
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaRegAddressBook } from "react-icons/fa6";
import { IoLibraryOutline } from "react-icons/io5";
import { SlGraduation } from "react-icons/sl";
import { GrPlan } from 'react-icons/gr';

export interface ActionItem {
  caption: string;
  link?: string;
  type: 'button' | 'label' | 'link' | 'heading' | 'dropdown';
  icon?: string;
  onClick?: () => void;
}

interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  logoWidth?: number;
  logoHeight?: number;
  navigation?: ActionItem[];
  onLogoClick?: () => void;
  logoMVV?: boolean;
}

const Header = ({
                  logoWidth = 108,
                  logoHeight = 60,
                  navigation,
                  onLogoClick,
                  logoMVV,
                }: HeaderProps) => {
  const { intl } = useIntlContext();
  const { userInfo, userCompany,permissions  } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => { return permissions && !!permissions[permission] };

  const [menuOpen, setMenuOpen] = useState(false); 

  const getIconByName = (name: string) => {
    switch (name) {
      case 'dashboard': return <LuBarChart size={20} />;
      case 'profile': return <LuUser2 size={20} />;
      case 'annualPlan': return <GrPlan size={20} />;
      case 'task': return <LuBookOpenCheck size={20} />;
      case 'investigations': return <LuSearch size={20} />;
      case 'actionsPlan': return <LuAreaChart size={18} />;
      case 'claims': return <LuBell size={20} />;
      case 'users': return <LuUsers2 size={20} />;
      case 'virtualAssistant': return <LuHeart size={20} />;
      case 'instructions': return <LuAlbum size={20} />;
      case 'Support': return <LuHelpCircle size={20} />;
      case 'lessons': return <LuBookOpenCheck size={20} />;
      case 'company': return <HiOutlineBuildingOffice2 size={20} />;
      case 'handbook': return <FaRegAddressBook size={20} />;
      case 'courses': return <SlGraduation size={20} />;
      case 'library': return <IoLibraryOutline size={20} />;
      default: return null;
    }
  };
  
  const renderActionItem = (item: ActionItem): ReactNode => {
    switch (item.type) {
      case 'link':
        return (
        <>
        <HideOnMobile>
          <StyledButton
            onClick={() => {
              if (item.onClick) item.onClick();
              setMenuOpen(false);
          }}>                                                   
            {formatMessage({ id: 'menu.' + item.caption, defaultMessage: item.caption })}
          </StyledButton>
        </HideOnMobile>
          <HideOnDesktop>
            <StyledButton
              onClick={() => {
                if (item.onClick) item.onClick();
                setMenuOpen(false);
            }}>           
              {getIconByName(item.caption)}
              {formatMessage({ id: 'menu.' + item.caption, defaultMessage: item.caption })}
            </StyledButton>
          </HideOnDesktop>
        </>
            );
      case 'dropdown':
        return <Label>{item.caption}</Label>;
      default:
        return null;
    }
  };

  const filteredNav = Object.entries(ASIDE_NAV).reduce((acc, [key, value]) => {
    acc[key] = value.filter((item:any) => canDo(item.permission));
    return acc;
  }, {} as { [key: string]: any[] });

  const color = userCompany?.main_color || '#A4366D';
  return (
    <HeaderWrapper>
      <PreContainer>
        <Logo onClick={onLogoClick}>
          <img src={userCompany?.logo?.url ? userCompany?.logo?.url : '/assets/logo.svg'} alt="Logo" />
        </Logo>
        <HamburgerIcon onClick={() => setMenuOpen(!menuOpen)}>
          &#9776;
        </HamburgerIcon>
        <HeaderContainer>
          <Navigation color={color}>
            {navigation?.map((actionItem) => (
              <Fragment key={actionItem.caption}>
                {renderActionItem(actionItem)}
              </Fragment>
            ))}
          </Navigation>
        </HeaderContainer>
        <div className="desktop-only">
          {canDo(HeaderPermission.change_company) ? <div style={{alignSelf :'center', marginRight: 20, width: 300}}><CompanySelector /></div> : <div style={{ width: 300, marginRight: 20, alignSelf : 'center'}}/>}
          <LanguageSwitcher/>
          <ProfileCircle name={userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname} />
        </div>
      </PreContainer>
      <MobileMenuWrapper isOpen={menuOpen}>
        <MobileMenu>
          <ProfileCircleMobile name={userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname} />        
          <div style={{ display: 'flex', alignItems: 'center' }}>       
            <LanguageSwitcherMobile />
            {canDo(HeaderPermission.change_company) && <div style={{width: '100%'}}> <CompanySelector /></div>} 
          </div>
        
        {navigation?.map((actionItem) => (
          <Fragment key={actionItem.caption}>
              {renderActionItem(actionItem)}
            </Fragment>
          ))}
          {Object.entries(filteredNav).map(([key, value]) => (
            <Fragment key={key}>
              {value.map((item: any) => (
                <StyledLink to={item.path} onClick={() => setMenuOpen(false)} key={item.name}>
                  {getIconByName(item.name)}
                  {formatMessage({ id: 'side_menu.' + item.name, defaultMessage: item.name })}
                </StyledLink>
              ))}
            </Fragment>
          ))}    
        
        </MobileMenu>
      </MobileMenuWrapper>
    </HeaderWrapper>
  );
};

export default Header;
