import React, { useState, useEffect } from 'react';
import { useGetAllTaskQuery, useGetTaskStatusesQuery } from '../../../slices/taskApiSlice';
import { CardGrid } from '../../../ui-kit/Card/CarsTaskDashboard';
import PaginableList from '../../../ui-kit/PaginableListDashboard';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import {  ButtonTabs } from '../Dashboard.styles';
import CustomSelectMulti from '../../../ui-kit/Dropdown/DashboardMultiStatus/DropdownMulti';
import DatePicker from 'react-datepicker'
import { Input, Button } from './TaskList.style';
import { RiRestartLine } from "react-icons/ri";
import Tooltip from '../../../ui-kit/ToolTip/TooltipContainer';


interface TaskListProps {
  user: any;
}

const FilterButtons: React.FC<{
  user: any,
  personal: any, setPersonal: any, mapStatuses: any,
  setSelectedStatus: any, selectedStatus: any, startDate: any,
  setStartDate: any, endDate: any, setEndDate: any

}> = ({ user, personal, setPersonal, selectedStatus, setSelectedStatus, mapStatuses, startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div style={{ paddingRight: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', gap: 10, flexWrap: 'wrap' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', cursor: 'normal', border: '0.063rem solid #00000017', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.05)' }}>
      <ButtonTabs
        style={{ paddingLeft: 15, paddingRight: 15 }}
        color={user}
        onClick={() => setPersonal(false)}
        active={!personal}
      >
        {formatMessage({ id: 'task.all', defaultMessage: 'All' })}
      </ButtonTabs>
      <ButtonTabs
        style={{ paddingLeft: 15, paddingRight: 15 }}
        color={user}
        onClick={() => setPersonal('1')}
        active={personal}
      >
        {formatMessage({ id: 'task.personal', defaultMessage: 'PERSONAL' })}
        </ButtonTabs>
      </div>
      
      <CustomSelectMulti
        value={selectedStatus}
        onChange={(selectedOption: any) => setSelectedStatus(selectedOption)}
        options={mapStatuses}
        placeholder={formatMessage({ id: 'task.filterByStage', defaultMessage: 'Filter by stage...' })}
      />
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
      <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          placeholderText={formatMessage({ id: 'dashboard.startDate', defaultMessage: 'Start Date' })}
          dateFormat="yyyy-MM-dd"
          customInput={<Input color={user} />}
        />
        <DatePicker
          selected={endDate}
          onChange={(date: any) => setEndDate(date)}
          placeholderText={formatMessage({ id: 'dashboard.endDate', defaultMessage: 'End Date' })}
          dateFormat="yyyy-MM-dd"
          customInput={<Input color={user} />}
      />
      <Tooltip title={formatMessage({ id: 'dashboard.resetDate', defaultMessage: 'Reset dates' })}>
      <Button 
        color={user}
        onClick={() => {
          setSelectedStatus([]);
          setStartDate(null);
          setEndDate(null);
          setPersonal(false);
        }}
      >
        <RiRestartLine style={{ fontSize: 14 }} />
      </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const TaskList: React.FC<TaskListProps> = ({ user }) => {
  const { intl } = useIntlContext();

  const { data: statuses } = useGetTaskStatusesQuery({});

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [statusPaginated, setStatusPaginated] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [overdue] = useState<any>(false);
  const [archived] = useState<any>(false);
  const [personal, setPersonal] = useState<any>(false);


  const mapStatuses = statuses?.data?.map((status: any) => ({ value: status.name, label: status.name }));

  useEffect(() => {
      setStatusPaginated(selectedStatus.map((status: any) => status.value));
  }, [selectedStatus]);

  return (
    <PaginableList
      query={useGetAllTaskQuery} my_page={true}
      currentPage={currentPage} overdue={overdue}
      archived={archived} personal={personal}
      statusPaginated={statusPaginated}
      startDate={startDate} endDate={endDate}
    >
      {(task, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>

        const cardsData = task.map((task: any) => ({
          id: task?.id,
          title: task?.title,
          assignee: task?.assignee,
          creator: task?.creator,
          taskType: task?.task_type,
          dueDate: task?.due_date,
          completedDate: task?.completed_date,
          font_color: task?.status?.font_color,
          background_color: task?.status?.bg_color,
          status: task?.status?.name,
          createdAt: task?.created_at,
          updatedAt: task?.updated_at,
          is_overdue: task?.is_overdue,
          is_archived: task?.is_archived,
        }));

        return <>
          <FilterButtons
            user={user}
            personal={personal} setPersonal={setPersonal}
            mapStatuses={mapStatuses} selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <CardGrid cards={cardsData} />
        </>;
      }}
    </PaginableList>
  );
};

export default TaskList;