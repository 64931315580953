import React, { useEffect } from 'react'
import { FormikInput, Row } from '../../../../ui-kit/'
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper'
import WangEditor from '../../../../ui-kit/Form/components/Editor/WysiwygEditor'
import { formatMessage } from '../../../../locale'

export type LessonMode = 'create' | 'edit' | 'view' | 'empty'

interface LessonFormProps {
  mode: LessonMode
  lesson?: any
  rerender?: any
  lessonData?: any
}

export const LessonFormContent = ({
  mode,
  lesson,
  rerender,
  lessonData,
}: LessonFormProps) => {
  useEffect(() => {
    if (lessonData && Array.isArray(lessonData.entity_files)) {
      const lessonUUIDs = lessonData.entity_files.map((file: any) => file.uuid)
      const updatedUuids = Array.from(new Set(lessonUUIDs))
      localStorage.setItem('uuids', JSON.stringify(updatedUuids))
    }
  }, [lessonData])
  return (
    <>
      <Row>
        <FormikInput
          label={formatMessage({
            id: 'lessons.title',
            defaultMessage: 'Title',
          })}
          name="title"
          placeholder={formatMessage({
            id: 'lessons.titlePlaceholder',
            defaultMessage: 'Enter a lesson title',
          })}
          autoComplete="off"
          required
        />
      </Row>
      <Row>
        <ImageUploadWrapper
          name={'cover_image'}
          type={'photos'}
        ></ImageUploadWrapper>
      </Row>
      <Row>
        <WangEditor name="content" />
      </Row>
    </>
  )
}
