import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../ui-kit/Button/Button';
import './Css/PopUpChangePassword.css';
import { useChangePasswordMutation } from '../../../slices/usersApiSlices';
import { IoEyeOffOutline } from 'react-icons/io5';
import { IoEyeOutline } from 'react-icons/io5';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { useSelector } from 'react-redux';
interface PopUpChangePasswordProps {
  onClose: () => void;
}

export const PopUpChangePassword: React.FC<PopUpChangePasswordProps> = ({ onClose }) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { intl, setLocale } = useIntlContext();

  const [showPasswordCurrent, setShowPasswordCurrent] = useState<boolean>(false);
  const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);
  const popUpRef = useRef<HTMLDivElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const [changePassword, { isLoading, isError, isSuccess }] = useChangePasswordMutation();

  const toggleShowPasswordCurrent = () => {
    setShowPasswordCurrent(!showPasswordCurrent);
  };

  const toggleShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  }
  const toggleShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  }

  async function handleSubmit() {
    const currentPassword = currentPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;


    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match');
      return;
    }
    try {
      await changePassword({
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword
      }).unwrap();
      setTimeout(() => {
        alert('Password changed successfully');
      }, 10);
      onClose();
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (popUpRef.current && !popUpRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay">
      <div ref={popUpRef} className="popup">
        <h2>
          {intl.locale === 'en' ? 'Change Password' : 'Ändra Lösenord'}
        </h2>
        <div style={{ marginTop: '25px' }}>
          <div style={{ position: 'relative', marginBottom: '1rem' }}>
            <input
              type={showPasswordCurrent ? "text" : "password"}
              placeholder={intl.locale === 'en' ? 'Current Password ' : 'Nuvarande Lösenord'}
              ref={currentPasswordRef}
              style={{ paddingRight: '2rem', borderColor: userCompany?.button_color || '#aa1168' }}
            />
            <div style={{ position: 'absolute', right: '0.5rem', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={toggleShowPasswordCurrent}>
              {showPasswordCurrent ? <IoEyeOutline/> : <IoEyeOffOutline />}
            </div>
          </div>
          <div style={{ position: 'relative', marginBottom: '1rem' }}>
            <input
              type={showPasswordNew ? "text" : "password"}
              placeholder={intl.locale === 'en' ? 'New Password' : 'Nytt Lösenord'}
              ref={newPasswordRef}
              style={{ paddingRight: '2rem', borderColor: userCompany?.button_color || '#aa1168' }}
            />
            <div style={{ position: 'absolute', right: '0.5rem', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={toggleShowPasswordNew}>
              {showPasswordNew ? <IoEyeOutline/> : <IoEyeOffOutline />}
            </div>
          </div>
          <div style={{ position: 'relative', marginBottom: '1rem' }}>
            <input
              type={showPasswordConfirm ? "text" : "password"}
              placeholder={intl.locale === 'en' ? 'Confirm Password' : 'Bekräfta Lösenord'}
              ref={confirmPasswordRef}
              style={{ paddingRight: '2rem', borderColor: userCompany?.button_color || '#aa1168' }}
            />
            <div style={{ position: 'absolute', alignSelf: 'center', right: '0.5rem', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={toggleShowPasswordConfirm}>
              {showPasswordConfirm ? <IoEyeOutline/> : <IoEyeOffOutline />}
            </div>
          </div>
        </div>
        {isError && <p className="error">Failed to change password</p>}
        {isSuccess && <p className="success">Password changed successfully</p>}
        <div className="button-group">
          <Button appearance="primary" onClick={handleSubmit} disabled={isLoading}>{intl.locale === 'en' ? 'Submit' : 'Bekräfta'}
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            {intl.locale === 'en' ? 'Cancel' : 'Avbryt'}
          </Button>
        </div>
      </div>
    </div>
  );
};