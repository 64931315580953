import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import Tooltip from '../ToolTip/TooltipContainerTask';
import { TaskStatus } from '../../enum/Task/TaskStatus';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { TaskPermisson } from '../../enum/Task/TaskPermisson';
import { Navigate } from 'react-router-dom';
import StatusIndicator from './StatusIndicator/StatusIndicator';
import { RiExpandUpDownFill } from "react-icons/ri";
import { FaSortAmountDownAlt, FaSortAmountDown, FaSort } from "react-icons/fa";

interface CardProps {
  id: number;
  title?: string;
  assignee?: any;
  creator?: any;
  taskType?: any;
  dueDate?: string;
  completedDate?: string;
  status?: any;
  createdAt?: string;
  updatedAt?: string;
  font_color?: string;
  background_color?: string;
  is_overdue?: boolean;
  is_archived?: boolean;
}

const CardContainer = styled.div`
  overflow: hidden;
  display: flex;
  corsor: pointer;
  flex-direction: column;
  width: 100%; /* Ensures the card takes up full width of its container */
  max-width: 428px; 
  min-width: 428px;
  padding: 30px;
  position: relative;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &:hover {
    transform: scale(1.05);
  }
`;


const TitleContainer = styled.div`
  height: 50px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 'normal';
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  margin: 10;
  font-size: 14px;
   color: grey;
  `;

  export const Actions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

export const Actions2 = styled.div`
position: absolute;
top: 15px;
left: 30px;
display: flex;
gap: 10px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;


const CardsWrapper = styled.div`
   width: 100%;
    overflow-x: auto;
`;

 const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

 const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

 const Tbody = styled.tbody`
    background-color: #fff;
`

 const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`
  const StyledLink = styled(Link)`

  `;

 const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

 const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`
const TdStatus = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`


 const TdButton = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`
export const Card: React.FC<CardProps> = ({
  id,
  title,
  assignee,
  taskType,
  dueDate,
  completedDate,
  font_color,
  background_color,
  status,
  is_overdue,
  is_archived,
  createdAt,
  updatedAt,
  creator,
}) => {
  const { intl } = useIntlContext();
  const { userCompany, permissions } = useSelector((state: any) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];
  const user = userCompany?.button_color;
  return (
    <Tr>
      
      <Td style={{width: '12%', wordBreak: 'break-word', textTransform: 'capitalize' }}> <StyledLink to={`/task/${id}`}>{title}</StyledLink></Td>
      <Td style={{ width: '15%' }}>
        <StyledLink to={`/task/${id}`}>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: 5 }}>       
          {assignee?.fullname}
          </div>
        </StyledLink>
      </Td>
      <Td style={{width: '20%', wordBreak: 'break-word'}}><StyledLink to={`/task/${id}`}>{taskType?.name}</StyledLink></Td>
      <Td style={{ width: '25%' }}>
        <StyledLink to={`/task/${id}`}>
          <p style={{ display: 'inline-block', fontSize: 12, borderRadius: '5px', padding: 5, color: font_color, backgroundColor: background_color, fontWeight: 'bold', textTransform: 'uppercase', wordBreak: 'break-word' }}>{status || 'N/A'} </p>
        </StyledLink>
      </Td>
      <Td style={{ wordBreak: 'break-word', }}>
        <StyledLink to={`/task/${id}`}>
          {!is_archived && (
          <StatusIndicator color="#009377" label={formatMessage({ id: 'task.active', defaultMessage: 'ACTIVE' })} />
            )}
            {is_archived && (
              <StatusIndicator color="#926900" label={formatMessage({ id: 'task.archived', defaultMessage: 'ARCHIVED' })} />
            )}
          {is_overdue && (
            <>
            <StatusIndicator color="red" label={formatMessage({ id: 'task.overdue', defaultMessage: 'OVERDUE' })} />     
            </>
            )}
        </StyledLink>
      </Td>
      <Td style={{ width: '13%', wordBreak: 'break-word' }}><StyledLink to={`/task/${id}`}>{dueDate || formatMessage({ id: 'task.noDueDate', defaultMessage: 'No due date' })} </StyledLink></Td>
      <Td><StyledLink to={`/task/${id}`}/></Td>
      
    </Tr>
  );
};

const DropdownOption: React.FC<{ sortOrder: any, selected: boolean, onClick: () => void, icon: React.ReactNode, label: string }> = ({ sortOrder, selected, onClick, icon, label }) => (
  <p
    style={{
      margin: 0,
      gap: 10,
      padding: '10px',
      cursor: 'pointer',
      fontWeight: 'normal',
      backgroundColor: selected ? '#a1cef8' : 'transparent',
      borderTopRightRadius: sortOrder === 'asc' ? 5 : 0,
      borderTopLeftRadius: sortOrder === 'asc' ? 5 : 0,
      borderBottomLeftRadius: sortOrder === 'desc' ? 5 : 0,
      borderBottomRightRadius: sortOrder === 'desc' ? 5 : 0,
    }}
    onClick={onClick}
  >
    {icon}
    {label}
  </p>
);

export const CardGrid: React.FC<{ cards: CardProps[] }> = ({ cards }) => {
  const [sortedCards, setSortedCards] = useState(cards);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const sorted = [...cards].sort((a, b) => {
      const dateA = new Date(a.dueDate || '').getTime();
      const dateB = new Date(b.dueDate || '').getTime();

      if (!a.dueDate) return sortOrder === 'asc' ? 1 : -1; // Place cards without due date at the end for ascending, beginning for descending
      if (!b.dueDate) return sortOrder === 'asc' ? -1 : 1; // Place cards without due date at the end for ascending, beginning for descending

      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortedCards(sorted);
  }, [cards, sortOrder]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && !(event.target as HTMLElement).closest('.dropdown-container')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortOrderChange = (order: 'asc' | 'desc') => {
    setSortOrder(order);
    setDropdownOpen(false);
  };

  return (
  <CardsWrapper>
    <Table style={{ marginTop: 20 }}>
      <Thead>
          <Tr>
          <Th>{formatMessage({ id: 'task.title', defaultMessage: 'Title' })}</Th>
          <Th>{formatMessage({ id: 'task.assign', defaultMessage: 'Assigne' })}</Th>
          <Th>{formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}</Th>
          <Th>{formatMessage({ id: 'task.stage', defaultMessage: 'Stage' })}</Th>
          <Th>{formatMessage({ id: 'task.status', defaultMessage: 'Status' })}</Th>
          <Th>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })} </Th>  
            <Th>
              <div className="dropdown-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , cursor: 'pointer', backgroundColor: dropdownOpen ? 'lightGrey' : 'transparent', borderRadius: 5 }} onClick={toggleDropdown}>
                <RiExpandUpDownFill size={25} />
              </div>
              {dropdownOpen && (
                <div className="dropdown-container" style={{ position: 'absolute', right: '2%', marginTop: 5, backgroundColor: 'white', borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  <DropdownOption
                    selected={sortOrder === 'asc'}
                    onClick={() => handleSortOrderChange('asc')}
                    icon={<FaSortAmountDownAlt size={12} style={{fontWeight: 'normal', marginRight: 5}} />}
                    label={formatMessage({ id: 'task.assending', defaultMessage: 'Ascending' })}
                    sortOrder={sortOrder}
                  />
                  <DropdownOption
                    selected={sortOrder === 'desc'}
                    onClick={() => handleSortOrderChange('desc')}
                    icon={<FaSortAmountDown size={12} style={{fontWeight: 'normal', marginRight: 5}} />}
                    label={formatMessage({ id: 'task.descending', defaultMessage: 'Descending' })}
                    sortOrder={sortOrder}
                  />
                </div>
              )}
            </Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedCards && sortedCards.length > 0 ? (
          sortedCards.map((card) => (
            <Card key={card.id} {...card} />
          ))
        ) : (
          <Tr>
            <Td colSpan={6} style={{ textAlign: 'center', height: '25vh'  }}>
            <h2>{formatMessage({ id: 'task.noTask', defaultMessage: 'No tasks available' })}</h2>
            </Td>
          </Tr>
        )}
      </Tbody>
      </Table>
  </CardsWrapper>
  );
};

