import React, { useState, useEffect, useMemo } from 'react'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'
import CustomSelect from '../../ui-kit/Dropdown/DashboardSettings/TaskDropdown'
import {
    useGetRisksQuery
  } from '../../slices/taskTypeApiSlice'
import Button from '../../ui-kit/Button/Button'
import { LIST } from '../../enum/Task/TaskFormPermisson'
import Toast from '../../ui-kit/Toast/ToastTask'
import { useDashboardSettingsMutation } from '../../slices/taskTypeApiSlice'
import { useGetUserInfoQuery } from '../../slices/usersApiSlices'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials } from '../../slices/authSlice'



function DashboardSettings() {
    const dispatch = useDispatch();
    const { intl } = useIntlContext()
    const { userInfo } = useSelector((state: any) => state.auth);
    const [selectedTaskType, setSelectedTaskType] = useState<any>(null)
    const [selectedCustomFields, setSelectedCustomFields] = useState<any>(null)
    const [popUpMessage, setPopUpMessage] = useState(false)
    const [availableCustomFields, setAvailableCustomFields] = useState<any>([]);

    const { data: risksData, refetch } = useGetRisksQuery({ custom_field_type: LIST.LIST });
    const { data: user, refetch: refetchUser } = useGetUserInfoQuery({}, {  });
    const [dashboardSettings] = useDashboardSettingsMutation();


  useEffect(() => {
    refetch();
  }, []);

 useEffect(() => {
   if (popUpMessage) {
     const timer = setTimeout(() => {
       setPopUpMessage(false);
     }, 4500);
 
     return () => clearTimeout(timer); 
   }
 }, [popUpMessage]);

    const mapOptionsTaskType = (optionsData: any) => optionsData?.map((option: any) => ({
        value: option.id,
        label: option.name,
      }))
    const optionsTaskType = useMemo(() => risksData ? mapOptionsTaskType(risksData.data.flat().map((risk: any) => risk)) : [], [risksData]);

    const mapCustomFields = (custom_fields: any) => custom_fields.map((field: any) => ({
        value: field.id,
        label: field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name,
    }))
       
    
    const taskCustomFields = useMemo(() => risksData?.data ? mapCustomFields(risksData.data.flat().map((risk: any) => risk.custom_fields).flat().map((field: any) => field)) : [], [risksData]);

  useEffect(() => {
    const taskTypeId = userInfo?.data?.dashboard_setting?.task_type_id;
    const customFieldId = userInfo?.data?.dashboard_setting?.custom_field_id;
    if (taskTypeId) {
      const taskTypeOption = optionsTaskType.find((option: any) => option.value === Number(taskTypeId));
      setSelectedTaskType({ value: taskTypeId, label: taskTypeOption?.label });
      if (taskTypeOption) {
        const selectedTask = risksData?.data.find((task: any) => task.id === Number(taskTypeId));
        if (selectedTask) {
          setAvailableCustomFields(mapCustomFields(selectedTask.custom_fields));
        }
      }
    }
    if (customFieldId) {
      setSelectedCustomFields({ value: customFieldId, label: taskCustomFields.find((field: any) => field.value === Number(customFieldId))?.label });
    }
  }, [userInfo?.data?.dashboard_setting?.task_type_id, userInfo?.data?.dashboard_setting?.custom_field_id, optionsTaskType, taskCustomFields, risksData]);

  useEffect(() => {
    if (selectedTaskType) {
      const selectedTask = risksData?.data.find((task: any) => task.id === selectedTaskType.value);
      if (selectedTask) {
        setAvailableCustomFields(mapCustomFields(selectedTask.custom_fields));
      }
    }
  }, [selectedTaskType, risksData]);
  
  
  const handleSubmit = async () => {
    const data = {
      task_type_id: selectedTaskType.value,
      custom_field_id: selectedCustomFields.value
    }
    try {
      await dashboardSettings({ data: data }).unwrap()
      refetchUser()
      dispatch(setCredentials(user as any));
    } catch (error) {
      console.error(error)
    }
    setPopUpMessage(true)
  }
 
  return (
              
<div style={{ flex: 'wrap', marginTop: 10, border: '1px solid rgba(234, 231, 242, 1)', boxShadow: '0 4px 8px    rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: 50, }}>       
    <h1 style={{ marginBottom: 20 }}>
        {formatMessage({ id: 'dashboardSettings.title', defaultMessage: 'Risks Dashboard' })}
    </h1>
    
    <CustomSelect
        height='3rem'
        label={formatMessage({ id: 'dashboardSettings.taskType', defaultMessage: 'Task type' })}
        disabled={false}
        value={selectedTaskType}
        options={optionsTaskType}
        onChange={(selectedOption) => {
          setSelectedTaskType(selectedOption)
          setSelectedCustomFields(null);
        }
        }
        placeholder={formatMessage({ id: 'dashboardSettings.selectType', defaultMessage: 'Select a task type...' })}
    />

    {selectedTaskType && (
        <div style={{ marginTop: 20 }}>
         <CustomSelect
            height='3rem'
            label={formatMessage({ id: 'dashboardSettings.customFields', defaultMessage: 'Custom fields' })}
            disabled={false}
            value={selectedCustomFields}
            options={availableCustomFields}
            onChange={(selectedOption) => setSelectedCustomFields(selectedOption)}
            placeholder={formatMessage({ id: 'dashboardSettings.selectField', defaultMessage: 'Select a custom field...' })}
        />
                  
          <Button
            type='button'
            color='primary'
            style={{ marginTop: 20, cursor: selectedTaskType && selectedCustomFields ? 'pointer' : 'default' }}
            disabled={!selectedTaskType || !selectedCustomFields}      
            onClick={() => {handleSubmit() }}
          >
            {formatMessage({ id: 'dashboardSettings.save', defaultMessage: 'Save' })}
          </Button>
        </div>
    )}
          
    {popUpMessage && <Toast message={formatMessage({ id: 'task.saved', defaultMessage: 'Saved!' })} />}

   
 </div>        
  )
}

export default DashboardSettings