import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { formatMessage } from '../../../../locale'
import Button from '../../../../ui-kit/Button/Button'
import {
  Actions,
  FormHeader,
  FormPaper,
  TabContent,
  FormContent,
  Column,
  ColumnTopLevel,
  TaskTypeContainer,
  Label,
  LabelWrapper,
  TextArea,
  LabelField,
  ColumnTopLevel2,
  IconButton,
  CheckboxWrapper
} from './TaskForm.Style'
import CustomSelect from '../../../../ui-kit/Dropdown/TaskDropdown'
import { paths } from '../../../../config/paths'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePicker.module.css'
import styleLIST from './DatePickerList.module.css'
import Row from '../../../../ui-kit/grid/Row'
import { FormikInput, FormikCheckbox, FormikCheckboxFalse } from '../../../../ui-kit'
import WangEditorTask from '../../../../ui-kit/Form/components/Editor/WangEditorTask';
import {  Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import {
  useGetAllTaskTypesQuery,
  useGetOneTaskTypeQuery,
  useGetOptionValueCustomFieldsQuery
} from '../../../../slices/taskTypeApiSlice'
import {
  useGetOneTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation
} from '../../../../slices/taskApiSlice'
import { useGetAllUsersQuery } from '../../../../slices/usersApiSlices'
import {
  DROPDOW_OPTIONS,
  INPUT_FILED,
  DATE_OPTIONS,
  TASK_FORM_OPTIONS,
  EDITOR,
  LIST,
  IMAGES
} from '../../../../enum/Task/TaskFormPermisson'
import { GrNext, GrPrevious } from "react-icons/gr";
import CustomSelectMulti from '../../../../ui-kit/Dropdown/TaskDropdownMulti'
import Progressbar from './Progressbar'
import WangEditorTaskCustomField from '../../../../ui-kit/Form/components/Editor/WangEditorTaskCustomField'
import { v4 as uuidv4 } from 'uuid';
import { useGetAllDepartmentsSimpleQuery } from '../../../../slices/departmentsApiSlice';
import { useGetAllOfficesSimpleQuery } from '../../../../slices/officeApiSlice';
import { useGetAllJobTitlesSimpleQuery } from '../../../../slices/jobTitleApiSlice';
import { useGetAllProjectGroupsSimpleQuery } from '../../../../slices/projectGroupApiSlice';
import { useGetRolesCompanyNoIdQuery } from '../../../../slices/companiesApiSlice';
import { transformToTree, flattenOptions } from '../../../../slices/apiSlice';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelectTaskForm';
import Toast from '../../../../ui-kit/Toast/ToastTask'
import ToastTaskForm from '../../../../ui-kit/Toast/ToastTaskForm'
import { LuPlusCircle } from "react-icons/lu";
import { LuTrash } from 'react-icons/lu';
import  ImageUpload  from '../../../../ui-kit/CustomFields/ImageUpload'
import { is } from 'immutable'

interface InvestigationFormPageProps {
  mode: 'create' | 'edit'
}

export const TaskForm = ({ mode }: InvestigationFormPageProps) => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { taskId } = useParams<{ taskId: string }>()
  const { userCompany, userInfo } = useSelector((state: any) => state.auth)

  const mainColor = userCompany?.main_color;
  const buttonColor = userCompany?.button_color;

  const [title, setTitle] = useState('')
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [popUpMessage, setPopUpMessage] = useState(false)
  const [zIndex, setZindex] = useState(true)
  const [popUpMessageError, setPopUpMessageError] = useState(false)
  const [errorMessages, setErrorMessages] = useState<any[]>([]);
  const [errorMessagesDetailed, setErrorMessagesDetailed] = useState<any[]>([]);
  const [expanded, setExpanded] = useState(false);

  const [selectedTaskType, setSelectedTaskType] = useState<any>(null)
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<any>(null)
  const [selectedStatus, setSelectedStatus] = useState<any>(null)
  const [departmentTree, setDepartmentTree] = useState<any[]>([]);
  const [backendListData, setBackendListData] = useState<any[]>([]);
 
  const { data: departmentsData } = useGetAllDepartmentsSimpleQuery({});
  const { data: officesData } = useGetAllOfficesSimpleQuery({});
  const { data: usersData } = useGetAllUsersQuery({});
  const { data: jobTitlesData } = useGetAllJobTitlesSimpleQuery({});
  const { data: projectGroupsData } = useGetAllProjectGroupsSimpleQuery({});
  const { data: rolesCompaniesData } = useGetRolesCompanyNoIdQuery({});

  const departments = departmentsData?.data || [];
  const offices = officesData?.data || [];
  const users = usersData?.data || [];
  const jobTitles = jobTitlesData?.data || [];
  const projectGroups = projectGroupsData?.data || [];
  const roles = rolesCompaniesData?.data || [];

  const [createTask] = useCreateTaskMutation()
  const [updatetask] = useUpdateTaskMutation()

  const {
    data: task,
    refetch: oneTaskrefetch
  } = useGetOneTaskQuery(
    { id: taskId, update: true },
    { skip: mode === 'create' }
  )

  const { data: taskTypesData, refetch: refetchAllTaskType } = useGetAllTaskTypesQuery({})
  const { data: taskType } = useGetOneTaskTypeQuery({ id: selectedTaskType?.value }, { skip: !selectedTaskType?.value })
  const { data: userList } = useGetAllUsersQuery({})

  const taskTypeOne = mode === 'create' ? taskType?.data?.custom_fields : task?.data?.custom_fields 

  const dropdownOptions = useMemo(() => {
    return taskType?.data?.custom_fields?.reduce((acc: any, field: any) => {
      if (field.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
        acc[field.id] = field.options?.map((option: any) => {
          const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || ''
          return { ...option, option_value: translatedValue }
        }) || []
      }
      if (field.field_type === LIST.LIST) {
        field.list_items.forEach((listItem: any) => {
          if (listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
            acc[listItem.id] = listItem.options?.map((option: any) => {
              const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || ''
              return { ...option, option_value: translatedValue }
            }) || []
          }
        })
      }
      return acc
    }, {}) || {}
  }, [taskType, intl.locale])
   
  useEffect(() => {
    refetchAllTaskType()
    setZindex(false)
    return () => {
      localStorage.removeItem('uuids')
      localStorage.removeItem('uuids2')
      localStorage.removeItem('uuidCustomField')
      localStorage.removeItem('frontId')
      localStorage.removeItem('frontId_content')
  };
  }, []);

  const initialValuesValues = mode === 'edit' ? task : taskType 
  
  const initialValues = {
    content: mode === 'edit'? task?.data?.content || '' : taskType?.data?.content  || '',
    due_date: mode === 'edit' ? task?.data?.due_date || null : null,
    is_archived: task?.data?.is_archived || false,
    is_overdue: task?.data?.is_overdue || false,
    is_simplified: mode === 'edit' && task?.data?.is_simplified ?  task?.data?.is_simplified : mode ==='create' && taskType?.data?.is_simplified ? taskType?.data?.is_simplified : false,
    allUsers: mode === 'edit' ? task?.data?.is_company_wide_access || false : taskType?.data?.is_company_wide_access || false,
    is_mandatory: mode === 'edit' ? task?.data?.is_mandatory || false : taskType?.data?.is_mandatory || false,
    departments: mode === 'edit' ? task?.data?.accesses?.departments?.map((dept: any) => dept.id) || [] : taskType?.data?.accesses?.departments?.map((dept: any) => dept.id) || [],
    offices: mode === 'edit' ? task?.data?.accesses?.offices?.map((office: any) => office.id) || [] : taskType?.data?.accesses?.offices?.map((office: any) => office.id) || [],
    job_titles: mode === 'edit' ? task?.data?.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [] : taskType?.data?.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [],
    project_groups: mode === 'edit' ? task?.data?.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [] : taskType?.data?.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [],
    users: mode === 'edit' ? task?.data?.accesses?.users?.map((user: any) => user.id) || [] : taskType?.data?.accesses?.users?.map((user: any) => user.id) || [],
    roles: mode === 'edit' ? task?.data?.accesses?.roles?.map((role: any) => role.id) || [] : taskType?.data?.accesses?.roles?.map((role: any) => role.id) || [],
    customFields: initialValuesValues?.data?.custom_fields?.reduce((acc: any, field: any) => {
      const entityValue = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
      const fieldValue = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
      switch (field.field_type) {
        case DROPDOW_OPTIONS.USER:
        case DROPDOW_OPTIONS.DEPARTMENT:
        case DROPDOW_OPTIONS.OFFICE:
        case DROPDOW_OPTIONS.JOB_TITLE:
        case DROPDOW_OPTIONS.PROJECT_GROUP:
            acc[field.id] = entityValue ? Array.isArray(entityValue) ? entityValue.map((val: any) => ({ value: val.id, label: val.name })) : { value: entityValue.id, label: entityValue.name } : null
          break
        case TASK_FORM_OPTIONS.DROPDOWN: 
            acc[field.id] = fieldValue ? fieldValue.map((val: any) => ({
              value: val.id,
              label: task?.data?.custom_fields?.find((cf: any) => cf.id === field.id)?.options?.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value
            })) : []
          break
        case INPUT_FILED.STRING:
        case INPUT_FILED.NUMBER:
          acc[field.id] = fieldValue || ""
          break
        case DATE_OPTIONS.DATE:
          if (fieldValue) {
            acc[field.id] = fieldValue || null 
          }
          break
        case EDITOR.EDITOR:
          acc[field.id] = mode === 'edit' ? fieldValue || '' : field.editor || ''
          if (field.entity_files) {
            const backUID = localStorage.getItem('uuidCustomField');
            const prevUUIDs = backUID ? JSON.parse(backUID) : [];
            const newUUIDs = field.entity_files.map((file: any) => file.uuid);
            const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
            localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
          }
          break
        case IMAGES.IMAGES:
          acc[field.id] = fieldValue || ""
          break
          case LIST.LIST:
            acc[field.id] = field.list_items.reduce((listAcc: any, listItem: any) => { 
              task?.data?.custom_field_list_values?.forEach((dataGroup: any) => {
                dataGroup.data.forEach((listValue: any) => {
                  if (listValue.custom_field_id === listItem.id) {
                    switch (listItem.field_type) {
                      case DROPDOW_OPTIONS.USER:
                      case DROPDOW_OPTIONS.DEPARTMENT:
                      case DROPDOW_OPTIONS.OFFICE:
                      case DROPDOW_OPTIONS.JOB_TITLE:
                      case DROPDOW_OPTIONS.PROJECT_GROUP:
                        listAcc[`${listItem.id}.${dataGroup.index}`] = Array.isArray(listValue.value)
                          ? listValue.value.map((val: any) => ({ value: val.id, label: val.name }))
                          : listValue.value
                          ? { value: listValue.value.id, label: listValue.value.name }
                          : null;
                        break;
            
                      case TASK_FORM_OPTIONS.DROPDOWN:
                        listAcc[`${listItem.id}.${dataGroup.index}`] = Array.isArray(listValue.value)
                          ? listValue.value.map((cf: any) => ({
                              value: cf.id,
                              label: listItem.options
                                .find((option: any) => option.id === cf.id)
                                ?.translations.find((translation: any) => translation.language_code === intl.locale)
                                ?.translated_value,
                            }))
                          : [];
                        break;
            
                      case DATE_OPTIONS.DATE:
                        listAcc[`${listItem.id}.${dataGroup.index}`] = listValue.value
                        break;
                      case INPUT_FILED.STRING:
                      case INPUT_FILED.NUMBER:
                        listAcc[`${listItem.id}.${dataGroup.index}`] = listValue.value || "";
                      break;
                      case IMAGES.IMAGES:
                        listAcc[`${listItem.id}.${dataGroup.index}`] = listValue.value || "";
                      break;
                      default:
                        console.warn("Unhandled field type:", listItem.field_type);
                        break;
                    }
                  }
                });
              });
              return listAcc;
            }, {});
            break;
          
          default:
            break;
          }
        return acc;      
    }, {}) || {}

    
  }

  useEffect(() => {
    if (departmentsData) {
      const tree = transformToTree(departmentsData.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departmentsData]);


  const { data: userOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.USER },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.USER || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.USER))
      )
    }
  )
  
  const { data: deparmentOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.DEPARTMENT },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.DEPARTMENT || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT))
      )
    }
  )
  
  const { data: officeOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.OFFICE },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.OFFICE || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.OFFICE))
      )
    }
  )
  
  const { data: jobTitleOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.JOB_TITLE },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.JOB_TITLE || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE))
      )
    }
  )
  
  const { data: projectGroupOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.PROJECT_GROUP },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP))
      )
    }
  )

  const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
    value: option.id,
    label: option.name || option.fullname,
  }))

  const mapOptionsStatus = (optionsData: any) => optionsData?.data?.statuses
    .filter((option: any) => option.is_start)
    .map((option: any) => ({
      value: option.id,
      label: option.name,
    }))

  const mapOptionsStatusEdit = (optionsData: any, selectedStatusId: any) => {
    const selectedStatus = optionsData?.find((option: any) => option.id === selectedStatusId);
    if (!selectedStatus) return [];
  
    const previousStatuses = new Map();
    const nextStatuses = new Map();
    const uniqueNames = new Set();
  
    const addStatuses = (statuses: any[], map: Map<any, any>) => {
      statuses.forEach((status: any) => {
        if (!uniqueNames.has(status.name)) {
          uniqueNames.add(status.name);
          map.set(status.id, { value: status.id, label: status.name, bg_color: status.bg_color, font_color: status.font_color });
        }
      });
    };
  
    addStatuses(selectedStatus.previous_statuses || [], previousStatuses);
    addStatuses(selectedStatus.next_statuses || [], nextStatuses);
  
    if (!uniqueNames.has(selectedStatus.name)) {
      uniqueNames.add(selectedStatus.name);
    }
  
    const combinedStatuses = [
      ...Array.from(previousStatuses.values()),
      { value: selectedStatus.id, label: selectedStatus.name, bg_color: selectedStatus.bg_color, font_color: selectedStatus.font_color },
      ...Array.from(nextStatuses.values())
    ];
  
    return combinedStatuses.filter((status, index, self) =>
      index === self.findIndex((s) => s.label === status.label)
    );
  };
  
  const optionsStatusEdit = mapOptionsStatusEdit(task?.data?.custom_field_statuses, task?.data?.status?.id);

  const mapOptionsDropdown = (optionsData: any) => optionsData?.map((option: any) => ({
    value: option.id,
    label: option.option_value,
    field_id: option.field_id
  }))

  const optionsUser = mapOptions(userOptions)
  const optionsDepartment = mapOptions(deparmentOptions)
  const optionsOffice = mapOptions(officeOptions)
  const optionsJobTitle = mapOptions(jobTitleOptions)
  const optionsProjectGroup = mapOptions(projectGroupOptions)
  const optionsAssignedTo = mapOptions(userList)
  const optionsTaskType  = mapOptions(taskTypesData)
  const optionsStatus = mapOptionsStatus(taskType)


  useEffect(() => {
    if (mode === 'edit' && taskId) {
      oneTaskrefetch()
    }
    if (task) {
      setSelectedTaskType({ value: task?.data?.task_type.id, label: task?.data?.task_type?.name })
      setSelectedAssignedTo({ value: task?.data?.assignee?.id, label: task?.data?.assignee?.fullname })
      setSelectedStatus({ value: task?.data?.status?.id, label: task?.data?.status?.name })
      setTitle(task?.data?.title)
      if (task?.data?.due_date) {
        setDueDate(new Date(task?.data?.due_date))
      }
     if (task?.data?.custom_field_list_values) { 
     setBackendListData(task?.data?.custom_field_list_values)
      } 
    }
  }, [mode, taskId, task])

  useEffect(() => {
    if (mode === 'create' && taskType?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType?.data?.entity_files?.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs))
    }
  }, [taskType?.data?.entity_files]);



  useEffect(() => {
    if (mode === 'edit' && task?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs))
    }
  }, [task?.data?.entity_files]);


  useEffect(() => {
    if (mode === 'create' && taskType?.data?.custom_fields?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType?.data?.custom_fields
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [taskType?.data?.custom_fields]);

  useEffect(() => {
    if (mode === 'edit' && task?.data?.custom_field_values?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.custom_field_values
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [task?.data?.custom_field_values]);

  const [noDueDate, setNoDueDate] = useState<boolean>(mode === 'edit' ? !initialValues.due_date : false);

  const handleNoDueDateChange = (setFieldValue: any) => {
    setNoDueDate(!noDueDate);
    if (!noDueDate) {
      setFieldValue('due_date', null);
    }
  };

  useEffect(() => {
    if (mode === 'edit' && initialValues.due_date) {
      setNoDueDate(false);
    } else if (mode === 'edit') {
      setNoDueDate(true);
    }
  }, [initialValues.due_date, mode]);

  const handleSubmit = async (values: any) => {
    const customFieldValues: any = []
    const customFieldEntityValues: any = []
    const custom_field_list_values: any = []
    const customUID = localStorage.getItem('uuidCustomField');
    const frontUID = localStorage.getItem('frontId_content');
  
    Object.keys(values.customFields).forEach((key) => {
      if (!isNaN(Number(key))) {
        const taskType = taskTypeOne.find((taskType: any) => taskType.id === Number(key))
        if (taskType && values.customFields[key] !== undefined && values.customFields[key] !== '') {
          if (taskType.is_input || (mode === 'edit' && taskType.is_output)) {
            if (taskType.field_type === DATE_OPTIONS.DATE) {
              customFieldValues.push({
                custom_field_id: Number(key),
                value: values.customFields[key] instanceof Date ? new Date(values.customFields[key].getTime() + (mode === 'create' ? (24 * 60 * 60 * 1000) : 0)) : values.customFields[key]
              })
            } else if (taskType.field_type === DROPDOW_OPTIONS.USER ||
              taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
              taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
              taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
              taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) {
              const getEntityValueId = (key: string, value: any) => {
                return task?.data?.custom_field_entity_values?.find(
                  (cf: any) => cf.custom_field_id === Number(key)
                )?.value.find((val: any) => val.id === value)?.entity_value_id;
              };
  
              const entityValues = Array.isArray(values.customFields[key])
                ? values.customFields[key].map((item: any) => ({
                  ...(item.value && { id: item.value }),
                  ...(getEntityValueId(key, item.value) && { entity_value_id: getEntityValueId(key, item.value) })
                }))
                : [{
                  id: values.customFields[key]?.value,
                  ...(getEntityValueId(key, values.customFields[key]?.value) && { entity_value_id: getEntityValueId(key, values.customFields[key]?.value) })
                }];
              if (entityValues.length > 0 && entityValues[0].id) {
                customFieldEntityValues.push({
                  custom_field_id: Number(key),
                  value: entityValues
                });
              }
            } else if (taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
              const dropdownValues = Array.isArray(values.customFields[key])
                ? values.customFields[key].map((item: any) => ({ id: item.value }))
                : [{ id: values.customFields[key]?.value }];
              if (dropdownValues.length > 0 && dropdownValues[0].id) {
                customFieldValues.push({
                  custom_field_id: Number(key),
                  value: dropdownValues
                });
              }
            } else if (taskType.field_type === EDITOR.EDITOR) {
              const editorField: any = {
                custom_field_id: Number(key),
                value: values.customFields[key]
              };
              if (customUID) {
                try {
                  const parsedBackUID = JSON.parse(customUID);
                  if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                    editorField.entity_files = {
                      uuid: frontUID || uuidv4(),
                      files: parsedBackUID.map((id: any) => ({
                        id,
                        tag: ""
                      })),
                    };
                  }
                } catch (error) {
                  console.error('Failed to parse backUID:', error);
                }
              }
              customFieldValues.push(editorField);
            } else if (taskType.field_type === INPUT_FILED.STRING || taskType.field_type === INPUT_FILED.NUMBER) {
              customFieldValues.push({
                custom_field_id: Number(key),
                value: values.customFields[key]
              })
            } else if (taskType.field_type === IMAGES.IMAGES) {
              customFieldValues.push({
                custom_field_id: Number(key),
                value: {
                  entity_type: 'task',
                  image: typeof values.customFields[key] === 'string' ? values.customFields[key] : values.customFields[key][0]
                }
              });
            } else if (taskType.field_type === LIST.LIST) {
              const listValuesMap: any = {};
              Object.keys(values.customFields[key]).map((listItemId: any) => {
                const listItemValue = values.customFields[key][listItemId]
                if (listItemId.includes('.')) {
                  const [customFieldId, index] = listItemId.split('.');
                  const listItem = taskType.list_items.find((listItem: any) => listItem.id === Number(customFieldId))?.field_type;
                  const indexData = backendListData.find((dataGroup: any) => dataGroup.index === Number(index))?.data.find((item: any) => item.custom_field_id === Number(customFieldId));
  
                  if (!listValuesMap[Number(index)]) {
                    listValuesMap[Number(index)] = [];
                  }
  
                  if (listItem === DROPDOW_OPTIONS.USER ||
                    listItem === DROPDOW_OPTIONS.DEPARTMENT ||
                    listItem === DROPDOW_OPTIONS.OFFICE ||
                    listItem === DROPDOW_OPTIONS.JOB_TITLE ||
                    listItem === DROPDOW_OPTIONS.PROJECT_GROUP) {
                    const value = Array.isArray(listItemValue)
                      ? listItemValue?.map((item: any) => {
                        const entityValue = indexData?.value.find((val: any) => val.id === item.value);
                        return { id: item?.value, value: item?.label, parent_id: Number(key), ...(entityValue ? { entity_value_id: entityValue.entity_value_id } : { custom_field_id: Number(key) }) };
                      })
                      : [{
                        id: listItemValue?.value,
                        value: listItemValue?.label,
                        parent_id: Number(key),
                        ...(indexData?.value.find((val: any) => val.id === listItemValue?.value) ? { entity_value_id: indexData.value.find((val: any) => val.id === listItemValue?.value).entity_value_id } : { custom_field_id: Number(key) })
                      }];
                    if (value?.length > 0 && value[0]?.id) {
                      listValuesMap[Number(index)].push({
                        custom_field_id: Number(customFieldId),
                        parent_id: Number(key),
                        value: value,
                        ...(indexData?.id && { id: indexData?.id })
                      });
                    }
                  } else if (listItem === TASK_FORM_OPTIONS.DROPDOWN) {
                    const value = Array.isArray(listItemValue)
                      ? listItemValue?.map((item: any) => {
                        const entityValue = indexData?.value.find((val: any) => val.id === item.value);
                        return entityValue ? { id: item?.value } : { custom_field_id: Number(key), id: item?.value };
                      })
                      : [{
                        id: listItemValue?.value,
                        ...(indexData?.value.find((val: any) => val.id === listItemValue?.value) ? {} : { custom_field_id: Number(key) })
                      }];
  
                    if (value.length > 0 && value[0].id) {
                      listValuesMap[Number(index)].push({
                        custom_field_id: Number(customFieldId),
                        parent_id: Number(key),
                        value: value,
                        ...(indexData?.id && { id: indexData?.id })
                      });
                    }
                  } else if (listItem === INPUT_FILED.STRING || listItem === INPUT_FILED.NUMBER || listItem === DATE_OPTIONS.DATE) {
                    if (listItemValue) {
                      listValuesMap[Number(index)].push({
                        custom_field_id: Number(customFieldId),
                        parent_id: Number(key),
                        value: listItemValue,
                        ...(indexData?.id && { id: indexData?.id })
                      });
                    } else {
                      listValuesMap[Number(index)].push({
                        custom_field_id: Number(customFieldId),
                        parent_id: Number(key),
                        value: values.customFields[key],
                      });
                    }
                  } else if (listItem === IMAGES.IMAGES) {
                    if (!listItemValue) {
                      return;
                    }
                    listValuesMap[Number(index)].push({
                      custom_field_id: Number(customFieldId),
                      parent_id: Number(key),
                      value: {
                        entity_type: 'task',
                        image: typeof listItemValue === 'string' ? listItemValue : listItemValue[0]
                      },
                      ...(indexData?.id && { id: indexData?.id })
                    });
                  }
                }
              });
              if (listValuesMap && mode === 'edit') {
                const listValues = Object.values(listValuesMap)
                custom_field_list_values.push(...listValues);
              }
            }
          }
        }
      }
    })
  
    Object.keys(duplicatedListItems).forEach((taskTypeId: any) => {
      duplicatedListItems[taskTypeId].forEach((copyIndex) => {
        const taskType = taskTypeOne.find((taskType: any) => taskType.id === Number(taskTypeId));
        if (taskType) {
          const listValues: any = [];
          taskType.list_items.forEach((listItem: any) => {
            const listItemValue = values.customFields[taskTypeId][`${listItem.id}_copy_${copyIndex}`];
            if (listItemValue) {
              const fildTypeOfListItem = taskType.list_items.find((item: any) => item.id === Number(listItem.id))?.field_type;
              if (fildTypeOfListItem === DROPDOW_OPTIONS.USER ||
                fildTypeOfListItem === DROPDOW_OPTIONS.DEPARTMENT ||
                fildTypeOfListItem === DROPDOW_OPTIONS.OFFICE ||
                fildTypeOfListItem === DROPDOW_OPTIONS.JOB_TITLE ||
                fildTypeOfListItem === DROPDOW_OPTIONS.PROJECT_GROUP) {
                const value = Array.isArray(listItemValue)
                  ? listItemValue?.map((item: any) => ({ id: item?.value, value: item?.label, parent_id: Number(taskTypeId), custom_field_id: Number(listItem.id) }))
                  : [{ id: listItemValue?.value, value: listItemValue?.label, parent_id: Number(taskTypeId), custom_field_id: Number(listItem.id) }];
                if (value?.length > 0 && value[0]?.id) {
                  listValues.push({
                    custom_field_id: listItem.id,
                    parent_id: Number(taskTypeId),
                    value: value
                  });
                }
              } else if (fildTypeOfListItem === TASK_FORM_OPTIONS.DROPDOWN) {
                const value = Array.isArray(listItemValue)
                  ? listItemValue?.map((item: any) => ({ id: item?.value, parent_id: Number(taskTypeId), custom_field_id: Number(taskTypeId) }))
                  : [{ id: listItemValue?.value, parent_id: Number(taskTypeId), custom_field_id: Number(taskTypeId) }];
                if (value.length > 0 && value[0].id) {
                  listValues.push({
                    custom_field_id: listItem.id,
                    parent_id: Number(taskTypeId),
                    value: value,
                  });
                }
              } else if (fildTypeOfListItem === INPUT_FILED.STRING || fildTypeOfListItem === INPUT_FILED.NUMBER || fildTypeOfListItem === DATE_OPTIONS.DATE) {
                if (listItemValue) {
                  listValues.push({
                    custom_field_id: listItem.id,
                    parent_id: Number(taskTypeId),
                    value: listItemValue
                  });
                }
              } else if (fildTypeOfListItem === IMAGES.IMAGES) {
                if (listItemValue) {
                  listValues.push({
                    custom_field_id: listItem.id,
                    parent_id: Number(taskTypeId),
                    value: {
                      entity_type: 'task',
                      image: listItemValue[0]
                    }
                  });
                }
              }
            }
          });
          if (listValues?.length > 0) {
            custom_field_list_values.push(listValues);
          }
        }
      });
    });
  
    if (mode === 'edit' && taskId) {
      customFieldValues.forEach((field: any) => {
        const matchingField = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id);
        if (matchingField) {
          field.id = matchingField.id;
        }
      });
  
      customFieldEntityValues.forEach((field: any) => {
        const matchingField = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id);
        if (matchingField) {
          field.id = matchingField.id;
        }
      });
    }
  
    try {
      const backUID2 = localStorage.getItem('uuids2')
      const backUID = localStorage.getItem('uuids');
      const frontUID = localStorage.getItem('frontId');
      
      let data: any = {
        title: title,
        content: values.content,
        due_date: noDueDate ? "" : values.due_date || "",
        task_type_id: selectedTaskType?.value,
        assignee_id: selectedAssignedTo?.value,
        creator_id: userInfo?.data?.id,
        status_id: selectedStatus?.value,
        custom_field_values: customFieldValues,
        is_simplified: values.is_simplified ? 1 : 0,
        custom_field_entity_values: customFieldEntityValues,
        custom_field_list_values: custom_field_list_values,
        is_company_wide_access: values.allUsers ? 1 : 0,
        archived: values.is_archived,
        overdue: values.is_overdue,
        access: {
          users: values.users,
          job_titles: values.job_titles,
          project_groups: values.project_groups,
          departments: values.departments,
          offices: values.offices,
          roles: values.roles,
        }
      }
  
      if (backUID || backUID2) {
        try {
          const parsedBackUID = JSON.parse(backUID || backUID2 || '[]');
          if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
            data = {
              ...data,
              entity_files: {
                uuid: frontUID || uuidv4(),
                files: parsedBackUID.map((id: any) => ({
                  id,
                  tag: ""
                })),
              },
            };
          }
        } catch (error) {
          console.error('Failed to parse backUID:', error);
        }
      }  
      data.status = 'published'
  
      if (mode === 'create') {
        await createTask({ data: data }).unwrap()
        navigate(paths.tasks)
      } else if (mode === 'edit' && taskId) {
        await updatetask({ id: taskId, data: data }).unwrap()
        navigate(paths.tasks)
      }
    } catch (error: any) {
      console.error('Failed to create/update investigation:', error)
      setErrorMessagesDetailed(Object.keys(error.data.errors).map((key: any) => error.data.errors[key]))
      setErrorMessages(error.data.message)
      setPopUpMessageError(true)
    }
  }

  function goToStep1() {
    setStep1(true)
    setStep2(false)
  }
  
  function goToStep2() {
    setStep2(true)
    setStep1(false)
  }

  function isSimplified() {
    setStep3(true)
    setStep1(false)
  }

  function goBackSimplified() {
    setStep1(true)
    setStep3(false)
  }
  function goToStepBack2() {
    setStep2(true)
    setStep3(false)
  }

  function goToStep3() {
    setStep3(true)
    setStep2(false)
  }

  const saveContentFileOnNext = () => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const existingUUIDs2 = localStorage.getItem('uuids2');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuids2 = existingUUIDs2 ? JSON.parse(existingUUIDs2) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuids2]));
  
      localStorage.setItem('uuids2', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
    goToStep3()
  }

  const saveContentFileOnBack = (is_simplified: any) => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const uuidCustomField = localStorage.getItem('uuidCustomField');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuidCustomField2 = uuidCustomField ? JSON.parse(uuidCustomField) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuidCustomField2]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
    
    if (is_simplified) {
      goBackSimplified()
    } else {
      goToStepBack2()
    }
  }

  const saveContentFile = () => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const existingUUIDs2 = localStorage.getItem('uuids2');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuids2 = existingUUIDs2 ? JSON.parse(existingUUIDs2) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuids2])); // Use Set to ensure uniqueness
  
      localStorage.setItem('uuids2', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
      setPopUpMessage(true);
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
  };

  const saveContentFileBack = () => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const uuidCustomField = localStorage.getItem('uuidCustomField');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuidCustomField2 = uuidCustomField ? JSON.parse(uuidCustomField) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuidCustomField2]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
      setPopUpMessage(true); // Show the popup message
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
  }
  
  
  useEffect(() => {
    if (popUpMessage) {
      const timer = setTimeout(() => {
        setPopUpMessage(false);
      }, 4500);
  
      return () => clearTimeout(timer); 
    }
  }, [popUpMessage]);

  useEffect(() => {
    if (popUpMessageError) {
      const timer = setTimeout(() => {
        setPopUpMessageError(false);
      }, expanded ? 4500 : 4500);
  
      return () => clearTimeout(timer); 
    }
  }, [popUpMessageError, expanded]);
  
  const handleClickOutside = (event: MouseEvent) => {
    const datePickerElement = document.querySelector(`.${styles.datePicker}`);
    if (datePickerElement && !datePickerElement.contains(event.target as Node)) {
      setZindex(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [duplicatedListItems, setDuplicatedListItems] = useState<{ [key: number]: number[] }>({});

  useEffect(() => {
    if (mode === 'create' && taskTypeOne) {
      const initialDuplicatedListItems: { [key: number]: number[] } = {};
      taskTypeOne.forEach((taskType: any) => {
        if (taskType.field_type === LIST.LIST) {
          initialDuplicatedListItems[taskType.id] = [0];
        }
      });
      setDuplicatedListItems(initialDuplicatedListItems);
    } else if (mode === 'edit' && task?.data?.custom_field_list_values.length == 0) {
      const initialDuplicatedListItems: { [key: number]: number[] } = {};
      taskTypeOne.forEach((taskType: any) => {
        if (taskType.field_type === LIST.LIST) {
          initialDuplicatedListItems[taskType.id] = [0];
        }
      });
      setDuplicatedListItems(initialDuplicatedListItems);
    }
  }, [mode, taskTypeOne]);

  const handleDuplicate = (taskTypeId: number) => {
     setDuplicatedListItems((prevItems) => {
        const newItems = { ...prevItems };
        if (!newItems[taskTypeId]) {
            newItems[taskTypeId] = [];
        } else {
            newItems[taskTypeId] = [...newItems[taskTypeId]];
        }
        newItems[taskTypeId].push(newItems[taskTypeId].length);
        return newItems;
    });
  };
  
  const handleDelete = (taskTypeId: number, index: number) => {
    setDuplicatedListItems((prevItems) => {
      const newItems = { ...prevItems };
      if (newItems[taskTypeId]) {
        newItems[taskTypeId] = newItems[taskTypeId].filter((_, i) => i !== index);
      }
      return newItems;
    });
  };

  useEffect(() => {
    if (mode === 'create' && taskType?.data?.statuses?.filter((status: any) => status.is_start).length === 1) {
      const startStatus = taskType.data.statuses.find((status: any) => status.is_start);
      setSelectedStatus({ value: startStatus.id, label: startStatus.name });
    }
  }, [taskType, mode]);
  
  
  return (
    <FormPaper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}

      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({
                      id: 'task.edit',
                      defaultMessage: 'Edit: '
                    }) + title
                    : formatMessage({
                      id: 'task.create',
                      defaultMessage: 'Create Task'
                    })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => {
                    navigate(paths.tasks)
                  }}
                >
                  {formatMessage({
                    id: 'investigationForm.cancel',
                    defaultMessage: 'Cancel'
                  })}
                </Button>
                <Button style={{cursor: !selectedTaskType ? 'default' : 'pointer'}} appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({
                      id: 'investigationForm.publish',
                      defaultMessage: 'Publish'
                    })
                    : formatMessage({
                      id: 'investigationForm.save',
                      defaultMessage: 'Save'
                    })}
                </Button>
              </Actions>
            </FormHeader>
            {mode === 'create' && (
                <Progressbar step1={step1} step2={step2} step3={step3} is_simplified={values.is_simplified}  mainColor={mainColor}  />
            )}

            {step1 && (
            <>
            <TabContent>
              <FormContent>
                <ColumnTopLevel>
                  <>
                    <TaskTypeContainer style={{marginBottom: 0}}>
                      <CustomSelect
                        disabled={mode === 'edit' ? true : false}  
                        label={formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}
                        value={selectedTaskType}
                        options={optionsTaskType}
                            onChange={(selectedOption) => {
                              setSelectedTaskType(selectedOption)
                              setSelectedStatus(null)
                            }}
                        placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
                      />
                        </TaskTypeContainer>  

                    {values.is_simplified &&
                          <>
                          <TaskTypeContainer style={{marginBottom: 0}}>
                          <CustomSelect
                            label={`${formatMessage({ id: 'task.taskName', defaultMessage: 'Task' })} Status`}
                            value={selectedStatus}
                            options={mode === 'edit' ? optionsStatusEdit : optionsStatus}
                            onChange={(selectedOption) => setSelectedStatus(selectedOption)}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} status...`}
                          />
                        </TaskTypeContainer>
                    <LabelWrapper style={{ gap: 0, }}>
                      <LabelField>{formatMessage({ id: 'task.taskName', defaultMessage: 'Task' })} {formatMessage({ id: 'task.title', defaultMessage: 'Title' })}</LabelField>
                      <TextArea
                        style={{marginBottom: 0, borderRadius: 8}}
                        color={buttonColor}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={formatMessage({ id: 'task.titlePlaceholder', defaultMessage: 'Enter a title for the task' })}
                      />
                    </LabelWrapper>
                    </>
                    }    
                        
                    <TaskTypeContainer>
                      <CustomSelect
                        label={formatMessage({ id: 'task.assignTo', defaultMessage: 'Assign to' })}
                        value={selectedAssignedTo}
                        options={optionsAssignedTo}
                        onChange={(selectedOption) => setSelectedAssignedTo(selectedOption)}
                        placeholder={formatMessage({ id: 'task.assignToPlaceholder', defaultMessage: 'Select a user...' })}
                      />
                    </TaskTypeContainer>
                        
                    {!values.is_simplified && !noDueDate && (
                      <LabelWrapper>
                        <FormikInput
                          label={formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })}
                          name="due_date"
                          placeholder={formatMessage({ id: 'library.releaseDatePlaceholder', defaultMessage: 'Enter a release date' })}
                          autoComplete="off"
                          type="date"
                        />
                      </LabelWrapper>
                    )}
                        {!values.is_simplified && <CheckboxWrapper>
                          <input
                            type="checkbox"
                            id="noDueDate"
                            checked={noDueDate}
                            onChange={() => handleNoDueDateChange(setFieldValue)}
                          />
                          <label htmlFor="noDueDate">
                            {formatMessage({ id: 'task.noDueDate', defaultMessage: 'No Due Date' })}
                          </label>
                        </CheckboxWrapper>}                        
                  </>
                    </ColumnTopLevel>

                <Column>
                  <div style={{marginTop: 10, gap: 10, display: 'flex', flexDirection: 'row'}}>
                        {!values.is_simplified && <FormikCheckbox
                          label={formatMessage({ id: 'handbookForm.checkBox', defaultMessage: 'All users' })}
                          name="allUsers"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const isChecked = e;
                            setFieldValue('allUsers', isChecked);                          
                          }}
                        />}
                        
                  {taskType?.data?.is_simplified && <FormikCheckboxFalse
                    label={formatMessage({ id: 'task.advanceView', defaultMessage: 'Advanced Task view' })}
                    name="is_simplified"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = e;
                      setFieldValue('is_simplified', isChecked);
                      if (isChecked) {
                        setFieldValue('departments', []);
                        setFieldValue('offices', []);
                        setFieldValue('job_titles', []);
                        setFieldValue('project_groups', []);
                        setFieldValue('users', []);
                        setFieldValue('roles', []);            
                        setNoDueDate(true);
                        setFieldValue('due_date', null);
                        setFieldValue('allUsers', true);
                      }
                    }}
                  />}
                  </div>
                  {!values.allUsers && !values.is_simplified && (     
                    <>
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.departmentLabel', defaultMessage: 'Department/Business Unit/Team' })}
                        name="departments"
                        options={departmentTree}
                        values={values.departments}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.officeLabel', defaultMessage: 'Office' })}
                        name="offices"
                        options={offices.map((office: any) => ({ value: office.id, label: office.name }))}
                        values={values.offices}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.jobTitleLabel', defaultMessage: 'Job Title' })}
                        name="job_titles"
                        options={jobTitles.map((jobTitle: any) => ({ value: jobTitle.id, label: jobTitle.name }))}
                        values={values.job_titles}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.groupProjectLabel', defaultMessage: 'Project group' })}
                        name="project_groups"
                        options={projectGroups.map((projectGroup: any) => ({ value: projectGroup.id, label: projectGroup.name }))}
                        values={values.project_groups}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.userLabel', defaultMessage: 'User' })}
                        name="users"
                        options={users.map((user: any) => ({ value: user.id, label: user.fullname }))}
                        values={values.users}
                        onChange={setFieldValue}
                      />
                     <MultiSelect
                        label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
                        name="roles"
                        options={roles.map((role: any) => ({ value: role.id, label: `${role.title}` })) || []}
                        values={values.roles}
                        onChange={(field, value) => {
                        setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? [] : value)}}
                      />
                    </>
                  )}
                    </Column>      
                  </FormContent>
                  <Button style={{ cursor: !selectedTaskType ? 'default' : 'pointer', width: '15%', marginTop: values.allUsers ? 30 : 0 }}
                    disabled={!selectedTaskType} appearance='secondary'
                    onClick={() => {
                      if(values.is_simplified) {
                        isSimplified()
                      } else {
                        goToStep2()
                      }
                    }}>
                    {formatMessage({ id: 'task.nextStep', defaultMessage: 'Next Step' })}
                    <GrNext />
                  </Button>     
            </TabContent>
              </>
            )}
            {step2 && (
              <>
                <FormContent>
                  <ColumnTopLevel2 style={{zIndex: 9998}}>  
                    <Row style={{ gap: 0}}>
                      <CustomSelect
                        label={'Status'}
                         value={selectedStatus}
                         options={mode === 'edit' ? optionsStatusEdit : optionsStatus}
                         onChange={(selectedOption) => setSelectedStatus(selectedOption)}
                         placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} status...`}
                        />
                    </Row>  
                    <Row style={{ gap: 0 }}>
                      <LabelField>{formatMessage({ id: 'task.title', defaultMessage: 'Title' })}</LabelField>
                        <TextArea
                        color={buttonColor}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={formatMessage({ id: 'task.titlePlaceholder', defaultMessage: 'Enter a title for the task' })}
                      />
                    </Row>
                    <Row style={{ gap: 0 }}>
                      <WangEditorTask name="content" /> 
                        <Button appearance='primary' style={{ width: '10%', marginTop: 10 }} onClick={saveContentFile}>
                          {formatMessage({id: 'investigationForm.save',defaultMessage: 'Save'})}
                        </Button> 
                    </Row>
                  </ColumnTopLevel2>
                </FormContent>    
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                  <Button appearance='secondary'
                    onClick={() => {          
                      goToStep1()
                    }}>
                    <GrPrevious />
                    {formatMessage({ id: 'task.previousStep', defaultMessage: 'Previous Step' })}
                  </Button>

                  <Button
                    style={{ cursor: !selectedTaskType ? 'default' : 'pointer' }}
                    appearance='primary'
                    onClick={() => {
                    saveContentFileOnNext()
                    }} >
                    {formatMessage({ id: 'task.nextStep', defaultMessage: 'Next Step' })}
                    <GrNext />
                  </Button>
                </div>
              </>
            )}

            {step3 && (
              <>
              <FormContent style={{flexDirection: 'column'}}>
                <ColumnTopLevel>        
                {Array.isArray(taskTypeOne) && taskTypeOne.filter?.((taskType: any) => taskType.is_input || (taskType.is_input && taskType.is_output))?.map((taskType: any) => {
                  const optionsDropdown = mapOptionsDropdown(dropdownOptions[taskType.id]) || []
                  return (
                    <TabContent key={taskType.id}>
                      {(taskType.field_type === INPUT_FILED.STRING || taskType.field_type === INPUT_FILED.NUMBER) ? (
                        <Row style={{gap: 0}}>
                          <FormikInput
                            label={taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                            name={`customFields.${taskType.id}`}
                            placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                            autoComplete="off"
                            required={taskType.is_required && mode === 'create'} 
                            />
                        </Row>
                      ) : (
                        taskType.field_type === DROPDOW_OPTIONS.USER ||
                        taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                        taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
                        taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                        taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                        <Row>
                          {taskType.is_multiselect ? (
                            <CustomSelectMulti
                              label={
                                <div style={{display: 'flex', flexDirection: 'row', gap: 3}}>
                                  {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                  {taskType.is_required && mode === 'create' && <p style={{color: mainColor}}>*</p>}
                                </div>
                              }
                              value={values.customFields[taskType.id]}
                              options={
                                taskType.field_type === DROPDOW_OPTIONS.USER
                                  ? optionsUser
                                  : taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                    ? optionsDepartment
                                    : taskType.field_type === DROPDOW_OPTIONS.OFFICE
                                      ? optionsOffice
                                      : taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                        ? optionsJobTitle
                                        : taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                          && optionsProjectGroup
                              }
                              onChange={(selectedOption) => {
                                setFieldValue(`customFields.${taskType.id}`, selectedOption)
                              }}
                              placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                            />
                                ) : (
                                  <CustomSelect
                                    label={
                                      <div style={{display: 'flex', flexDirection: 'row', gap: 3}}>
                                        {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                        {taskType.is_required && mode === 'create' && <p style={{color: mainColor}}>*</p>}
                                      </div>
                                    }
                                    value={values.customFields[taskType.id]}
                                    options={
                                      taskType.field_type === DROPDOW_OPTIONS.USER
                                        ? optionsUser
                                        : taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                          ? optionsDepartment
                                          : taskType.field_type === DROPDOW_OPTIONS.OFFICE
                                            ? optionsOffice
                                            : taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                              ? optionsJobTitle
                                              : taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                                && optionsProjectGroup
                                    }
                                    onChange={(selectedOption) => {
                                      setFieldValue(`customFields.${taskType.id}`, selectedOption)
                                    }}
                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                  />
                                )}       
                        </Row>
                          ) : taskType.field_type === DATE_OPTIONS.DATE ? (
                            <Row>
                              <LabelWrapper>
                                  <FormikInput
                                    label={taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                    name={`customFields.${taskType.id}`}
                                    placeholder={formatMessage({ id: 'library.releaseDatePlaceholder', defaultMessage: 'Enter a release date' })}
                                    autoComplete="off"
                                    type="date"
                                    required={taskType.is_required && mode === 'create'}
                                  />
                              </LabelWrapper>
                            </Row>
                          ) : taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                <Row>
                                  {taskType.is_multiselect ? (
                                    <CustomSelectMulti
                                      label={
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                          {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                          {taskType.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                        </div>
                                      }
                                      value={values.customFields[taskType.id]}
                                      options={optionsDropdown}
                                      onChange={(selectedOption) => {
                                        setFieldValue(`customFields.${taskType.id}`, selectedOption)
                                      }}
                                      placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                    />
                                  ) : (
                                    <CustomSelect
                                      label={
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                          {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                          {taskType.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                        </div>
                                      }
                                      value={values.customFields[taskType.id]}
                                      options={optionsDropdown}
                                      onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}`, selectedOption)}
                                      placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                    />
                                  )}
                                </Row>      
                        ) : taskType.field_type === EDITOR.EDITOR ? (
                          <Row>
                            <LabelField style={{marginBottom: 0, marginTop: 55}}>{taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</LabelField>
                              <WangEditorTaskCustomField name={`customFields.${taskType.id}`} /> 
                              <Button
                                appearance='primary'
                                style={{ width: '10%', marginTop: 10 }}
                                onClick={saveContentFileBack}>
                                {formatMessage({ id: 'investigationForm.save', defaultMessage: 'Save' })}
                              </Button> 
                          </Row>
                                ) : taskType.field_type === IMAGES.IMAGES ? (
                                  <>
                                    <Label style={{marginTop: 10}}>
                                      <div>
                                        {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                        {taskType.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                      </div>
                                    </Label>
                                    <Row style={{ border: '1px dashed #ccc', borderRadius: 8, width: '100%', height: '3rem', display: 'flex',  cursor: 'pointer', position: 'relative' }}>
                                      <ImageUpload
                                        label={'Click here to upload'}
                                        mainColor={mainColor}
                                        value={values.customFields[taskType.id]}
                                        onChange={(e: any) => {
                                          setFieldValue(`customFields.${taskType.id}`, e.currentTarget.files);
                                        }}
                                        onRemove={() => {
                                          setFieldValue(`customFields.${taskType.id}`, null);
                                        }}  
                                    />        
                                  </Row>  
                            </>        
                        ) : taskType.field_type === LIST.LIST ? (
                          <div key={taskType.id} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  borderRadius: 15, padding: 20 }}>
                            <h2 style={{ marginBottom: 20 }}>{taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</h2>         
                              {mode === 'edit' && backendListData.map((dataGroup: any) => {
                                const validItems = dataGroup.data?.filter((listValue: any) => 
                                  taskType.list_items.some((listItem: any) => listValue?.custom_field_id === listItem.id)
                                );
                              
                                if (validItems.length === 0) {
                                  return null;
                                }                        
                                return (
                                  <div key={dataGroup.index} style={{ width: '100%', borderRadius: 20, border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', marginBottom: 20, padding: 15, display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 5, width: '100%', justifyContent: 'space-between' }}>
                                      <div></div>
                                      <IconButton type='button'
                                        onClick={() => {
                                          setBackendListData((prevData: any) => {
                                          const newData = prevData.filter((data: any) => data.index !== dataGroup.index);
                                          const index = dataGroup.index;
                                          const custom_field_id = dataGroup.data.map((data: any) => data.custom_field_id);
                                          const parentCustomFieldId = dataGroup.data[0]?.parent_custom_field_id;
                                          
                                            custom_field_id.forEach((id: any) => {
                                              setFieldValue(`customFields.${parentCustomFieldId}["${id}.${index}"]`, undefined);
                                            })     
                                          return newData;
                                          });
                                        }}
                                      >
                                        <LuTrash color='red' size={25} />
                                      </IconButton>
                                    </div>                                   
                                    {taskType.list_items.filter((listItem: any) => listItem.is_input || (listItem.is_input && listItem.is_output)).map((listItem: any) => {        
                                      const listValue = dataGroup.data?.find((value: any) => value.custom_field_id === listItem.id) || {};
                                      const optionsDropdownList = mapOptionsDropdown(dropdownOptions[listItem.id]) || [];
                                      return (
                                        <div key={dataGroup.index} style={{ marginBottom: 20, width: '33%' }}>
                                          {listItem.field_type === INPUT_FILED.STRING || listItem.field_type === INPUT_FILED.NUMBER ? (
                                            <Row style={{ marginTop: 10 }}>
                                              <FormikInput
                                                label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                name={`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`}
                                                placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                autoComplete="off"
                                                required={listItem.is_required && mode === 'create' as any}
                                                value={listValue.value || ''}
                                              />
                                            </Row>
                                          ) : listItem.field_type === DROPDOW_OPTIONS.USER ||
                                              listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                              listItem.field_type === DROPDOW_OPTIONS.OFFICE ||
                                              listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                              listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                                              listItem.is_multiselect ? (
                                                <Row>
                                                  <CustomSelectMulti
                                                    height='3rem'
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {listItem.is_required && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}.${dataGroup.index}`] || []}
                                                    options={
                                                      listItem.field_type === DROPDOW_OPTIONS.USER
                                                        ? optionsUser
                                                        : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                                          ? optionsDepartment
                                                          : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                                            ? optionsOffice
                                                            : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                                              ? optionsJobTitle
                                                              : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                                              && optionsProjectGroup
                                                    }
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    onChange={(selectedOption) => {
                                                      setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)
                                                    }}
                                                  />
                                                </Row>
                                              ) : (
                                                <Row>
                                                  <CustomSelect
                                                    height='3rem'
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {taskType.is_required && mode === 'create' as any && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}.${dataGroup.index}`] || null}
                                                    options={
                                                      listItem.field_type === DROPDOW_OPTIONS.USER
                                                        ? optionsUser
                                                        : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                                          ? optionsDepartment
                                                          : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                                            ? optionsOffice
                                                            : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                                              ? optionsJobTitle
                                                              : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                                              && optionsProjectGroup
                                                    }                                                       
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    onChange={(selectedOption) => {
                                                      setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)
                                                    }}
                                                  />
                                                </Row>
                                              )
                                          ) : listItem.field_type === DATE_OPTIONS.DATE ? (
                                                <Row style={{ marginTop: 10 }}>
                                                  <LabelWrapper>
                                                  <FormikInput
                                                    label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                    name={`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`}
                                                    placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    autoComplete="off"
                                                    type="date"
                                                    required={taskType.is_required && mode === 'create' as any} 
                                                  />
                                                  </LabelWrapper>
                                                </Row>
                                          ) : listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                                listItem.is_multiselect ? (
                                                  <Row>
                                                    <CustomSelectMulti
                                                      height='3rem'
                                                      label={
                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                          {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                          {listItem.is_required && mode === 'create' as any && <p style={{ color: mainColor }}>*</p>}
                                                        </div>
                                                      }
                                                      value={values.customFields[taskType.id]?.[`${listItem.id}.${dataGroup.index}`] || []}
                                                      options={optionsDropdownList}
                                                      onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                                      placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    />
                                                  </Row>
                                            ) : (
                                              <Row>
                                                <CustomSelect
                                                  height='3rem'
                                                  label={
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                      {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                      {listItem.is_required && mode === 'create' as any && <p style={{ color: mainColor }}>*</p>}
                                                    </div>
                                                  }
                                                  value={values.customFields[taskType.id]?.[`${listItem.id}.${dataGroup.index}`] || null}
                                                  options={optionsDropdownList}
                                                  onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                                  placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                />
                                              </Row>
                                            )
                                            ) : listItem.field_type === IMAGES.IMAGES && (
                                                <>
                                                  <Label style={{marginTop: 10}}>
                                                    <div>
                                                      {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}{listItem.is_required && <p style={{ color: mainColor }}>*</p>}
                                                      {listItem.is_required && mode === 'create' as any && <p style={{ color: mainColor }}>*</p>}
                                                    </div>
                                                  </Label>
                                                  <Row style={{ border: '1px dashed #ccc', borderRadius: 8, width: '100%', height: '3rem', display: 'flex', cursor: 'pointer', position: 'relative'}}>                                                  
                                                    <ImageUpload
                                                      label={'Click here to upload'}
                                                      mainColor={mainColor}
                                                      value={values.customFields[taskType.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                                      onChange={(e: any) => {
                                                        setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, e.currentTarget.files);
                                                      }}
                                                      onRemove={() => {
                                                        setFieldValue(`customFields.${taskType.id}["${listItem.id}.${dataGroup.index}"]`, null);
                                                      }}   
                                                    />
                                                  </Row>
                                              </>
                                        )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                              {duplicatedListItems[taskType.id] && duplicatedListItems[taskType.id].map((copyIndex) => (   
                                <div style={{ display: 'flex', width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', borderRadius: 20, padding: 20, flexDirection: 'column', marginBottom: 20 }}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 10 }}>
                                    <div></div>
                                    <IconButton type='button' onClick={() => handleDelete(taskType.id, copyIndex)} >
                                      <LuTrash color='red' size={25} />
                                    </IconButton>
                                  </div>
                                <div key={`${taskType.id}_copy_${copyIndex}`} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                {taskType.list_items.filter((listItem: any) => listItem.is_input || (listItem.is_input && listItem.is_output)).map((listItem: any)  => {
                                    const optionsDropdownList = mapOptionsDropdown(dropdownOptions[listItem.id]) || [];
                                      return (
                                        <div key={`${listItem.id}_copy_${copyIndex}`} style={{ marginBottom: 20, width: '33%' }}>
                                          {listItem.field_type === INPUT_FILED.STRING || listItem.field_type === INPUT_FILED.NUMBER ? (
                                            <Row style={{marginTop: 10}}>                                                                                      
                                              <FormikInput
                                                label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                name={`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`}
                                                placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                autoComplete="off"
                                                required={listItem.is_required && mode === 'create'}
                                              />
                                            </Row>
                                          ) : listItem.field_type === DROPDOW_OPTIONS.USER ||
                                              listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                              listItem.field_type === DROPDOW_OPTIONS.OFFICE ||
                                              listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                              listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                                              listItem.is_multiselect ? (
                                                <Row>
                                                  <CustomSelectMulti
                                                    height='3rem'    
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {listItem.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                                                    options={
                                                      listItem.field_type === DROPDOW_OPTIONS.USER
                                                        ? optionsUser
                                                        : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                                          ? optionsDepartment
                                                          : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                                            ? optionsOffice
                                                            : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                                              ? optionsJobTitle
                                                              : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                                              && optionsProjectGroup
                                                    }                                                  
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    onChange={(selectedOption) => { setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, selectedOption) }}
                                                  />
                                                </Row>
                                              ) : (
                                                <Row>                                      
                                                  <CustomSelect
                                                    height='3rem'
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {listItem.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                                                    options={
                                                      listItem.field_type === DROPDOW_OPTIONS.USER
                                                        ? optionsUser
                                                        : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                                          ? optionsDepartment
                                                          : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                                            ? optionsOffice
                                                            : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                                              ? optionsJobTitle
                                                              : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                                              && optionsProjectGroup
                                                    }                                                      
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    onChange={(selectedOption) => {
                                                      setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)
                                                    }}
                                                  />
                                                </Row>
                                              )
                                            ) : listItem.field_type === DATE_OPTIONS.DATE ? (
                                              <Row style={{marginTop: 10}}>
                                                <LabelWrapper>
                                                <FormikInput
                                                label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                name={`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`}
                                                placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                autoComplete="off"
                                                type='date'      
                                                required={listItem.is_required && mode === 'create'}
                                              />
                                                </LabelWrapper>
                                              </Row>
                                            ) : listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                              listItem.is_multiselect ? (
                                                <Row>
                                                  <CustomSelectMulti
                                                    height='3rem'
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {listItem.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                                                    options={optionsDropdownList}
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                    onChange={(selectedOption) => {
                                                      setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)
                                                    }}
                                                  />
                                                </Row>
                                              ) : (
                                                <Row>
                                                  <CustomSelect
                                                    height='3rem'
                                                    label={
                                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                        {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                                        {listItem.is_required && mode === 'create' && <p style={{ color: mainColor }}>*</p>}
                                                      </div>
                                                    }
                                                    value={values.customFields[taskType.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                                                    options={optionsDropdownList}
                                                    onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)}
                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                                  />
                                                </Row>
                                              )
                                              ) : listItem.field_type === IMAGES.IMAGES && ( 
                                                <>
                                                  <Label style={{ marginTop: 10 }}>
                                                    <div>
                                                      {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}{listItem.is_required && <p style={{ color: mainColor }}>*</p>}
                                                      {listItem.is_required && mode === 'create' as any && <p style={{ color: mainColor }}>*</p>}
                                                    </div>
                                                  </Label>
                                                  <Row style={{ border: '1px dashed #ccc', borderRadius: 8, width: '100%', height: '3rem', display: 'flex',  cursor: 'pointer', position: 'relative' }}>
                                                    <ImageUpload
                                                      label={'Click here to upload'}
                                                      mainColor={mainColor}
                                                      value={values.customFields[taskType.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                                                      onChange={(e: any) => {
                                                        setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, e.currentTarget.files);
                                                      }}
                                                      onRemove={() => {
                                                        setFieldValue(`customFields.${taskType.id}.${listItem.id}_copy_${copyIndex}`, null);
                                                      }}                                           
                                                    />             
                                                  </Row> 
                                                </>
                                          )}
                                        </div>
                                  );
                                })}
                              </div>
                              </div>
                              ))}                            
                              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 15}}>
                                <button type='button' style={{ backgroundColor: 'transparent', border: 'none', display: 'flex', alignSelf: 'flex-end', color: userCompany?.button_color, cursor: 'pointer' }} onClick={() => handleDuplicate(taskType.id)}><LuPlusCircle size={30} /> </button>
                              </div>
                        </div>     
                      ) : null)}
                    </TabContent>
                  );
                })}
                  </ColumnTopLevel>
                  <Button appearance='secondary'
                  style={{ marginTop: 20, width: '20%' }}
                  onClick={() => {
                    saveContentFileOnBack(values.is_simplified)
                  }}
                >
                  <GrPrevious />
                  {formatMessage({ id: 'task.previousStep', defaultMessage: 'Previous Step' })}
                </Button>
            </FormContent>               
              </>
            )}
          </Form>
        )}
      </Formik>
      {popUpMessageError && <ToastTaskForm messageDetailed={errorMessagesDetailed} message={errorMessages} expanded={expanded} setExpanded={setExpanded} />}
      {popUpMessage && <Toast message={formatMessage({ id: 'task.saved', defaultMessage: 'Saved!' })} />}
    </FormPaper>
  )
}

export default TaskForm