import React, { useEffect, useState } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import {
  Container, FlexContainer, ButtonTabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TdText,
  TableContainer
 } from './Dashboard.styles';
import { CircleChart } from '../../ui-kit/Chart/CircleChart';
import { PerformanceChart } from '../../ui-kit/Chart/PerformanceChart';
import CustomCalendar from '../../ui-kit/Calendar/CustomCalendar';
import CourseCard from '../../ui-kit/Card/CourseCard';
import {
  useGetCompanyDashboardQuery,
  useGetPersonalDashboardQuery,
} from '../../slices/dashboardApiSlice';
import { useGetCustomFieldDashboardQuery, useGetOneTaskTypeQuery } from '../../slices/taskTypeApiSlice';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Hero from '../../ui-kit/Hero/Hero';
import { DashboardPermissions } from '../../enum/Dashboard/DashboardPermissions';
import { RiExpandUpDownFill } from "react-icons/ri";
import { FaSortAmountDownAlt, FaSortAmountDown} from "react-icons/fa";
import { TaskList } from '../Dashboard/TaskList/TaskList';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Viewimage from '../../ui-kit/CustomFields/ViewImage'

const DropdownOption: React.FC<{ sortOrder: any, selected: boolean, onClick: () => void, icon: React.ReactNode, label: string }> = ({ sortOrder, selected, onClick, icon, label }) => (
  <p
    style={{
      margin: 0,
      gap: 10,
      padding: '10px',
      cursor: 'pointer',
      fontWeight: 'normal',
      backgroundColor: selected ? '#a1cef8' : 'transparent',
      borderTopRightRadius: sortOrder === 'asc' ? 5 : 0,
      borderTopLeftRadius: sortOrder === 'asc' ? 5 : 0,
      borderBottomLeftRadius: sortOrder === 'desc' ? 5 : 0,
      borderBottomRightRadius: sortOrder === 'desc' ? 5 : 0,
    }}
    onClick={onClick}
  >
    {icon}
    {label}
  </p>
);

export const Dashboard = () => {
  const { intl } = useIntlContext();
  const location = useLocation();
  const { userInfo, permissions, userCompany } = useSelector((state: any) => state.auth);
  const [filter, setFilter] = useState<string>('1');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const user = userCompany?.button_color;

  const canDo = (permission: string) => {
    return permissions && !!permissions[permission];
  };
  const canSeeDashboardCompany = canDo(DashboardPermissions.isAllowedSeeDashboardCompany);

  const [courseData, setCourseData] = useState([
    { title: '', percent: 0 },
    { title: '', percent: 0 },
    { title: '', percent: 0 },
  ]);

  const [performanceData, setPerformanceData] = useState([
    { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: 0 },
    { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: 0 },
  ]);

  const [courses, setCourses] = useState([]);
  
  const {
    data: companyData,
    refetch: refetchCompany,
  } = useGetCompanyDashboardQuery({}, { skip: !canSeeDashboardCompany });

  const {
    data: personalData,
    refetch: refetchPersonal,
  } = useGetPersonalDashboardQuery({}, { skip: canSeeDashboardCompany });

  const dashboardData = canSeeDashboardCompany ? companyData : personalData;
  const refetch = canSeeDashboardCompany ? refetchCompany : refetchPersonal;
  
  const { data: coursesData, refetch: refetchCourses,} = useGetPersonalDashboardQuery({})

  const { data: customFieldData, refetch: refetchCustomFieldDashboard } = useGetCustomFieldDashboardQuery(
    { taskTypeId: userInfo?.data?.dashboard_setting?.task_type_id, customFieldId: userInfo?.data?.dashboard_setting?.custom_field_id },
    { skip: !userInfo?.data?.dashboard_setting?.task_type_id || !userInfo?.data?.dashboard_setting?.custom_field_id });
  
  const { data: taskTypeData } = useGetOneTaskTypeQuery(
    { id: userInfo?.data?.dashboard_setting?.task_type_id },
    { skip: !userInfo?.data?.dashboard_setting?.task_type_id || !userInfo?.data?.dashboard_setting?.custom_field_id });
  
  useEffect(() => {
    if ((canSeeDashboardCompany && companyData) || (!canSeeDashboardCompany && personalData)) {
      refetch();
    }
    refetchCourses();
  }, [location.key, refetch, canSeeDashboardCompany, companyData, personalData]);

  useEffect(() => {
    if (dashboardData) {
      setCourseData([
        { title: formatMessage({ id: 'dashboard.completed', defaultMessage: 'Completed' }), percent: dashboardData.data.statistic?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.inProgress', defaultMessage: 'In progress' }), percent: dashboardData.data.statistic?.in_progress || 0 },
        { title: formatMessage({ id: 'dashboard.toBegin', defaultMessage: 'To begin' }), percent: dashboardData.data.statistic?.to_begin || 0 },
      ]);

      setPerformanceData([
        { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: dashboardData.data.performance?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: dashboardData.data.performance?.failed || 0 },
      ]);
    }
  }, [dashboardData, intl]);

  useEffect(() => {
    if (coursesData) {
      setCourses(coursesData.data.courses.map((course: any) => (course)))
    }
  }, [coursesData, intl])

  useEffect(() => {
    if (userInfo?.data?.dashboard_setting?.task_type_id && userInfo?.data?.dashboard_setting?.custom_field_id){
      refetchCustomFieldDashboard();
    }
  } , [userInfo?.data?.dashboard_setting?.task_type_id, userInfo?.data?.dashboard_setting?.custom_field_id])

  const welcome = formatMessage({ id: 'hero.header_22', defaultMessage: ', welcome to the ' });

  const handleSortOrderChange = (order: 'asc' | 'desc') => {
    setSortOrder(order);
    setDropdownOpen(false);
  };
  
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const exportTableToExcel = async (sortOrder: 'asc' | 'desc') => {
    const table = document.getElementById('risksTable');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('RisksData');

    // Add table headers
    const headers: any = [];
    if (table) {
      const headerCells = table.querySelectorAll('thead th');
      headerCells.forEach((headerCell) => {
        // Exclude non-data elements
        if (!headerCell.querySelector('.dropdown-container')) {
          headers.push((headerCell as HTMLElement).innerText);
        }
      });
    }
    worksheet.addRow(headers);

    // Add table data
    const rows: any[] = [];
    const rowCells = table ? table.querySelectorAll('tbody tr') : [];
    rowCells.forEach((rowCell) => {
      const rowData: any = [];
      rowCell.querySelectorAll('td').forEach((cell) => {
        // Exclude non-data elements
        if (!cell.querySelector('.dropdown-container')) {
          rowData.push(cell.innerText);
        }
      });
      rows.push(rowData);
    });

    // Sort rows based on the first column (or any other column)
    rows.sort((a, b) => {
      const valueA = a[0];
      const valueB = b[0];
      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    // Add sorted rows to the worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    // Apply custom styles to the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: '000000' }, size: 10 }; 
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } }; 
      cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    });

    // Adjust column widths based on content
    worksheet.columns.forEach((column) => {
      if (column && column.eachCell) {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength + 2; // Add some padding
      }
    });

    // Write the workbook to a buffer and save it as an Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, 'RisksData.xlsx');
  };

  
  const handleExportClick = () => {
    exportTableToExcel(sortOrder);
  };


  return (
    <Container>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Hero
          description={formatMessage({
            id: 'hero.description',
            defaultMessage:
              'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.',
          })}
          imagePath={'assets/hero2.jpg'}
          header={formatMessage({ id: 'hero.header_2', defaultMessage: userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname + welcome })}
          subHeader={formatMessage({ id: 'hero.subHeaderDashboard', defaultMessage: 'Here you will find your dashboard' })}
        ></Hero>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', cursor: 'normal', marginBottom: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '296px', height: '36px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
          <ButtonTabs active={filter === '1'} color={user} style={{ width: 164 }} onClick={() => setFilter('1')}>DASHBOARD</ButtonTabs>
          <ButtonTabs active={filter === '2'} color={user} style={{ width: 154, textTransform: 'uppercase' }} onClick={() => setFilter('2')}>{formatMessage({ id: 'dashboard.myCourses', defaultMessage: 'My courses' })}</ButtonTabs>
        </div>
      </div>
      {filter === '1' && (
      <>
      <FlexContainer>
        <CircleChart data={courseData} title={formatMessage({ id: 'dashboard.courseStatistics', defaultMessage: 'Course Statistics' })} />
        <PerformanceChart data={performanceData} title={formatMessage({ id: 'dashboard.performanceStatistics', defaultMessage: 'Performance' })} />
        <CustomCalendar />
      </FlexContainer>
          
        <div style={{ flex: 'wrap', marginTop: 50, border: '1px solid rgba(234, 231, 242, 1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: 50, }}>     
          <TaskList user={user} />
          </div>
          

            <div style={{ flex: 'wrap', marginTop: 50, border: '1px solid rgba(234, 231, 242, 1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: 50, }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
              <h1>
                {formatMessage({ id: 'dashboard.risks', defaultMessage: 'Action plan' })}
              </h1>
              {customFieldData && <button onClick={handleExportClick} style={{ padding: '10px 20px', backgroundColor: userCompany?.button_color, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                {formatMessage({ id: 'dashboard.exportToExel', defaultMessage: 'Export to Excel' })}
              </button>}
            </div>      
            
            {customFieldData ? (
               <>
                <TableContainer>
                <Table id="risksTable">
                  <Thead>
                    <Tr>
                      <Th>{formatMessage({ id: 'dashboard.title', defaultMessage: 'Title' })}</Th>
                         {taskTypeData?.data?.custom_fields?.find((field: any) => field.id === Number(userInfo?.data?.dashboard_setting?.custom_field_id))?.list_items?.map((field: any) => (
                        <Th key={field.id} style={{ textTransform: 'capitalize' }}>
                          {field.translations
                            .filter((translation: any) => translation.language_code === intl.locale)
                            .map((translation: any) => translation.translated_name)}
                        </Th>
                      ))}
                          <Th>{formatMessage({ id: 'dashboard.creator', defaultMessage: 'Creator' })}</Th>
                      <Th>{formatMessage({ id: 'dashboard.createdDate', defaultMessage: 'Date' })}</Th>
                        <Th>
                                    <div className="dropdown-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , cursor: 'pointer', backgroundColor: dropdownOpen ? 'lightGrey' : 'transparent', borderRadius: 5 }} onClick={toggleDropdown}>
                                      <RiExpandUpDownFill size={25} />
                                    </div>
                                    {dropdownOpen && (
                                      <div className="dropdown-container" style={{ position: 'absolute', right: '2%', marginTop: 5, backgroundColor: 'white', borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                        <DropdownOption
                                          selected={sortOrder === 'asc'}
                                          onClick={() => handleSortOrderChange('asc')}
                                          icon={<FaSortAmountDownAlt size={12} style={{fontWeight: 'normal', marginRight: 5}} />}
                                          label={formatMessage({ id: 'task.assending', defaultMessage: 'Ascending' })}
                                          sortOrder={sortOrder}
                                        />
                                        <DropdownOption
                                          selected={sortOrder === 'desc'}
                                          onClick={() => handleSortOrderChange('desc')}
                                          icon={<FaSortAmountDown size={12} style={{fontWeight: 'normal', marginRight: 5}} />}
                                          label={formatMessage({ id: 'task.descending', defaultMessage: 'Descending' })}
                                          sortOrder={sortOrder}
                                        />
                                      </div>
                                    )}
                                  </Th>
                        </Tr>
                  </Thead>
                  <Tbody>
                {customFieldData?.data?.flat().sort((a: any, b: any) => {
                  const dateA = new Date(a.task.created_at);
                  const dateB = new Date(b.task.created_at);
                  return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
                }).map((item: any, index: number) => (
                  <Tr key={index}>
                    <TdText style={{ textTransform: 'capitalize', width: '15%', wordBreak: 'break-word', fontWeight: 400 }}>{item.task.title}</TdText>
                    {taskTypeData?.data?.custom_fields?.find((field: any) => field.id === Number( userInfo?.data?.dashboard_setting?.custom_field_id))?.list_items?.map((field: any) => {
                      const data = item.data.find((data: any) => data.custom_field_id === field.id);
                      return (
                        <TdText key={field.id} style={{ width: '15%', wordBreak: 'break-word' }}>
                          {data ? (
                            Array.isArray(data.value) ? (
                              data.value.map((value: any, valueIndex: number) => (
                                <span key={valueIndex}>
                                  {value.name || value.translations?.find((t: any) => t.language_code === intl.locale)?.translated_value || value.option_value || value}
                                  {valueIndex < data.value.length - 1 && ', '}
                                </span>
                              ))
                            ) : typeof data.value === 'object' ? (
                              data.value.date ? (
                                <span>{new Date(data.value.date).toLocaleDateString()}</span>
                              ) : (
                                Object.entries(data.value).map(([key, val], objIndex) => (
                                  <span key={objIndex}>
                                    {key}: {val as any}
                                    {objIndex < Object.entries(data.value).length - 1 && ', '}
                                  </span>
                                ))
                              )
                              ) : typeof data.value === 'string' && data.value.startsWith('http') ? (
                                <div style={{ width: '80%'}}>
                                    <Viewimage value={data.value} mainColor={userCompany?.button_color} />
                                </div>
                            ) : (
                              <span>{data.value}</span>
                            )
                          ) : 'N/A'}
                        </TdText>
                      );
                    })}
                    <TdText style={{ width: '15%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        {item?.task?.assignee?.firstname} {item?.task?.assignee?.lastname}
                      </div>
                    </TdText>
                    <TdText style={{ width: '20%' }}>
                      {new Date(item.task.created_at).toLocaleDateString()}
                    </TdText>
                    <Td style={{ width: '5%' }}>

                    </Td>
                  </Tr>
                ))}
              </Tbody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', width: '100%' }}>
                  <h2 style={{ fontWeight: 'normal', color: 'grey' }}>
                    {formatMessage({ id: 'dashboard.noRisk', defaultMessage: 'Select Risk in the dashboard settings to view the risks.' })}
                </h2>
              </div>
            )}
            </div>
        </>
      )}

    {filter === '2' && (
      <>
           {courses.length > 0 ? (
            <>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
              {courses.map((course: any, index: number) => (
                <CourseCard key={index} course={course} />
              ))}
            </div>
            </>  
          ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
            <h1>{formatMessage({ id: 'task.noCourses', defaultMessage: 'No courses available' })}</h1>
          </div>
          )}
      </>
    )}
         
    </Container>
  );
};

export default Dashboard;
