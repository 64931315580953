import styled from 'styled-components'

const Row = styled.div<{ $breakpoint?: any; $columns?: number }>`
  width: 100%;
  flex: 1;
  display: grid;
  gap: 1.5rem;

  ${({ $breakpoint, theme }) => `
    @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `}
`

const Row2 = styled.div<{ $breakpoint?: any; $columns?: number }>`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns ?? 2}, 1fr);
  gap: 1.5rem;

  ${({ $breakpoint, theme }) => `
    @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `}
`

export const Flexbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; 
  margin-top: 1rem;
  padding-bottom: 0.8rem;

  &:hover {
    overflow-x: auto;
    transition: overflow-x 0.3s;
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 2px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    transition: background 0.3s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &:not(:hover) &::-webkit-scrollbar-thumb {
    transition: background 0.3s, opacity 0.3s;
    opacity: 0;
  }
`

export default Row
