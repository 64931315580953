import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: fixed;
  bottom: 60px;
  right: 30px;
  cursor: pointer;
`;

const TooltipText = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

interface TooltipFixedProps {
  title: string;
  children: React.ReactNode;
}

const TooltipFixed: React.FC<TooltipFixedProps> = ({ title, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      <TooltipText visible={visible}>{title}</TooltipText>
    </TooltipContainer>
  );
};

export default TooltipFixed;

export {};
