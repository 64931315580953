import React, { useState, useEffect } from 'react';

import { ListContainer, ListItem, Actions, Button, AddButton, Header } from './OfficeList.styles';
import { LuFileEdit, LuTrash, LuPlus } from 'react-icons/lu';
import OfficePopup from './OfficePopup';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useDeleteOfficeMutation, useGetAllOfficesQuery } from '../../../../../slices/officeApiSlice'
import ButtonSecondary from '../../../../../ui-kit/Button/ui/components/ButtonSecondary'

interface Office {
  id: number;
  name: string;
}

export const OfficeList: React.FC = () => {
  const { intl } = useIntlContext();
  const { data: officesData, error, isLoading, refetch } = useGetAllOfficesQuery({});
  const [deleteOffice] = useDeleteOfficeMutation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<Office | undefined>(undefined);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const handleAdd = () => {
    setSelectedOffice(undefined);
    setMode('create');
    setIsPopupOpen(true);
  };

  const handleUpdate = (office: Office) => {
    setSelectedOffice(office);
    setMode('edit');
    setIsPopupOpen(true);
  };

  const handleDelete = async (officeId: number) => {
    try {
      await deleteOffice({ id: officeId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Error deleting office:', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedOffice(undefined);
  };

  const handlePublish = () => {
    refetch();
    handleClosePopup();
  };

  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'office.errorLoadingOffices', defaultMessage: 'Error loading offices' })}</div>;

  return (
    <ListContainer>

      {officesData?.data.map((office: Office) => (
        <ListItem key={office.id}>
          <span>{office.name}</span>
          <Actions>
            <Button onClick={() => handleUpdate(office)}><LuFileEdit size={16} /></Button>
            <Button onClick={() => handleDelete(office.id)}><LuTrash size={16} /></Button>
          </Actions>
        </ListItem>
      ))}
      {isPopupOpen && (
        <OfficePopup
          office={selectedOffice}
          mode={mode}
          onClose={handleClosePopup}
          onPublish={handlePublish}
        />
      )}

      <Header style={{marginTop:'20px'}}>
        <ButtonSecondary onClick={handleAdd}>
          <LuPlus size={20} />
          {formatMessage({ id: 'office.newOfficeButton', defaultMessage: 'New Office' })}
        </ButtonSecondary>
      </Header>
    </ListContainer>
  );
};
