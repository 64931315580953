export enum CustomFieldPermisson {
    STRING = 'string',
    NUMBER = 'number',
    DROPDOWN = 'dropdown',
    USER = 'user',
    DATE = 'date',
    DEPARTMENT = 'department',
    OFFICE = 'office',
    JOB_TITLE = 'job-title',
    PROJECT_GROUP = 'project-group',
    EDITOR = 'editor',
    TaskTypesInArray = 'lists',
    IMAGES  = 'image',
}