import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../ui-kit/Button/Button'
import Row, { Flexbox } from '../../ui-kit/grid/Row'
import { useTabs } from '../../ui-kit/Tabs/Tabs'
import { FormContent, FormHeader, FormPaper, TabsMobile } from './Company2.styles'
import { CompanyDetails } from './components/CompanyDetails/CompanyDetails'
import { CompanyStructure } from './components/CompanyStructure/CompanyStructure'
import { Offices } from './components/Offices/Offices'
import { LuPlus } from 'react-icons/lu'
import { paths } from '../../config/paths'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { JobTitle } from './components/JobTitle/JobTitle'
import { ProjectGroup } from './components/ProjectGroup/ProjectGroup'
import UploadUsers from '../UploadUsers/UploadUsers'
import TaskType from '../TaskType/TaskType'
import DashboardSettings from '../DashboardSettings/DashboardSettings'
import { CompanyTabsPermissions } from '../../enum/Company/CompanyTabsPermissions'
import { setSelectedTab } from '../../slices/selectedTabSlice';

export const Company2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { userInfo, permissions } = useSelector((state: RootState) => state.auth)
  const selectedTab = useSelector((state: RootState) => state.selectedTab.selectedTab)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }
  const role = userInfo?.data?.role?.name || ''
 
  const handleSelectTab = (tabIndex: number) => {
    dispatch(setSelectedTab(tabIndex)); 
  };

  const tabs = [
    {
      label: formatMessage({ id: 'company2.companiesTab', defaultMessage: 'Companies' }),
      content: <CompanyDetails role={role} />,
      canDo: CompanyTabsPermissions.companyViewOwn,
    },
    {
      label: formatMessage({ id: 'company2.structureTab', defaultMessage: 'Company Structure' }),
      content: <CompanyStructure />,
      canDo: CompanyTabsPermissions.departmentView,
    },
    {
      label: formatMessage({ id: 'company2.officesTab', defaultMessage: 'Offices' }),
      content: <Offices />,
      canDo: CompanyTabsPermissions.officeView,
    },
    {
      label: formatMessage({ id: 'company2.jobTitlesTab', defaultMessage: 'Job Titles' }),
      content: <JobTitle />,
      canDo: CompanyTabsPermissions.jobTitleView,
    },
    {
      label: formatMessage({ id: 'company2.projectGroupsTab', defaultMessage: 'Project Groups' }),
      content: <ProjectGroup />,
      canDo: CompanyTabsPermissions.projectGroupView,
    },
    {
      label: formatMessage({ id: 'company2.taskTypes', defaultMessage: 'Task Type' }),
      content: <TaskType />,
      canDo: CompanyTabsPermissions.taskTypeView,
    },
    {
      label: formatMessage({ id: 'company2.usersImportTab', defaultMessage: 'Import Users' }),
      content: <UploadUsers />,
      canDo: CompanyTabsPermissions.userImport,
    },
    {
      label: formatMessage({ id: 'company2.dahboardSettings', defaultMessage: 'Dashboard Settings' }),
      content: <DashboardSettings />,
      canDo: CompanyTabsPermissions.companyCreate,
    }
  ]

  const availableTabs = tabs.filter(tab => canDo(tab.canDo))

  const tabsComponents = useTabs({
    selectedTab,
    onTabSelect: handleSelectTab,
    tabs: availableTabs,
  })


  return (
    <FormPaper>
      <FormHeader>
        <Flexbox>
          <div style={{ minWidth: '120%' }}>
          <tabsComponents.Buttons />  
          </div>
          <TabsMobile>
            <tabsComponents.Buttons />  
          </TabsMobile>
        </Flexbox>
          {canDo(CompanyTabsPermissions.companyCreate)  && (
            <Button appearance="primary" type="button" onClick={() => navigate(paths.createCompany)}>
              <LuPlus size={20} />
              {formatMessage({ id: 'company2.newCompanyButton', defaultMessage: 'New company' })}
            </Button>
          )}
      </FormHeader>
      <FormContent>
        <tabsComponents.Content />
      </FormContent>
    </FormPaper>
  )
}
