const DropdownOption: React.FC<{ sortOrder: 'asc' | 'desc', selected: boolean, onClick: () => void, label: string }> = ({ sortOrder, selected, onClick, label }) => (
  <p
    style={{
      margin: 0,
      gap: 10,
      padding: '10px',
      cursor: 'pointer',
      fontWeight: 'normal',
      backgroundColor: selected ? '#a1cef8' : 'transparent',
    }}
    onClick={onClick}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#d3eaff')}
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = selected ? '#a1cef8' : 'transparent')}
  >
    {label}
  </p>
);
export default DropdownOption;
