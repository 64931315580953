import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';

interface CustomSelectProps {
  label: any;
  value: any;
  options: { value: any; label: string; bg_color?: string; font_color?: string }[];
  onChange: (selectedOption: any) => void;
  placeholder: string;
  disabled?: boolean;
  currentStatusColor?: any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, value, options, onChange, placeholder, disabled, currentStatusColor }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
   const customStyles: StylesConfig = {
     control: (provided, state) => ({
       ...provided,
       textAlign: 'left',
       fontWeight: 'normal',
       fontSize: 14,
       borderRadius: 8,
       maxWidth: 300,
       margin: 5,
       borderColor: value?.length ? userCompany?.button_color : 'rgba(0, 0, 0, 0.08)',
       borderWidth: value?.length ? '1px' : provided.borderWidth,
       boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : '0 4px 8px rgba(0, 0, 0, 0.03)',
       '&:hover': {
         borderColor: userCompany?.button_color,
       },
     }),
     option: (provided, state) => {
      const option = options.find(opt => opt.value === (state.data as any).value);
      return {
        ...provided,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        backgroundColor: option?.bg_color || provided.backgroundColor,
        color: option?.font_color || provided.color,
        padding: 10,
        width: '85%',
        margin: 10,
        borderRadius: 8,
        cursor: 'pointer',
        whiteSpace: 'normal', 
        wordBreak: 'break-word',
        overflowX: 'hidden',
        '&:hover': {
          filter: 'brightness(0.75) !important',
        },
      };
    },
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
     }),
    
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
     }),
     valueContainer: (provided) => ({
      ...provided,
       backgroundColor: currentStatusColor?.bg_color || provided.backgroundColor,
       color: currentStatusColor?.font_color || provided.color, 
       fontWeight: 'bold',
       borderRadius: 8,
       margin: 5,
       justifyContent: 'center',
       textAlign: 'center',
     }),
     dropdownIndicator: (base, state) => {
      let changes = {
        transform: 'rotate(180deg)',
      };
       return Object.assign(base, changes);
      },
    };

  return (
    <div>
      <Select
        menuPlacement="top"
        inputId="custom-select"
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable
        styles={customStyles}
        aria-label={label}
        isDisabled={disabled}
      />
    </div>
  );
};

export default CustomSelect;