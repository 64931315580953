import React, { useState, useEffect } from 'react';
import {
  useGetAllDepartmentsQuery,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} from '../../../../../slices/departmentsApiSlice';
import {
  ListContainer,
  ListItem,
  Button,
  Actions,
} from './DepartmentsList.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuFileEdit, LuTrash, LuPlus, LuChevronDown, LuChevronRight, LuUser } from 'react-icons/lu';
import DepartmentPopup from './DepartmentPopup'; // Import the popup component
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useSelector } from 'react-redux';
interface Department {
  id: number;
  name: string;
  parent?: { id: number; name: string } | null;
  head_of_department?: { id: number; fullname: string } | null;
  children?: Department[];
}

export const DepartmentsList: React.FC = () => {
  const {userCompany} = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: departmentsData, error, isLoading, refetch } = useGetAllDepartmentsQuery({});
  const [updateDepartment] = useUpdateDepartmentMutation();
  const [deleteDepartment] = useDeleteDepartmentMutation();
 
  const [isTreeExpanded, setIsTreeExpanded] = useState(false);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
  const [parentDepartment, setParentDepartment] = useState<{ id: number | null; name: string } | null>(null);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  useEffect(() => {
    refetch();
  }, [location.key, refetch]);

  useEffect(() => {
    if (departmentsData) {
      const initialExpandedState: { [key: number]: boolean } = {};
      departmentsData.data.forEach((department: Department) => {
        initialExpandedState[department.id] = false; // Set everything to be closed by default
      });
      setExpanded(initialExpandedState);
    }
  }, [departmentsData]);

  const handleUpdate = (department: Department) => {
    setSelectedDepartment(department);
    setParentDepartment(department.parent ? { id: department.parent.id, name: department.parent.name } : null);
    setMode('edit');
    setIsPopupOpen(true);
  };

  const handleDelete = async (departmentId: number) => {
    try {
      await deleteDepartment({ id: departmentId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Error deleting department:', error);
    }
  };

  const handleToggle = (departmentId: number) => {
    setExpanded(prev => ({ ...prev, [departmentId]: !prev[departmentId] }));
  };

  const handleAdd = (parentDepartment: { id: number | null; name: string }) => {
    setSelectedDepartment(undefined);
    setParentDepartment(parentDepartment);
    setMode('create');
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setParentDepartment(null);
    setSelectedDepartment(undefined);
  };

  const handlePublish = (departmentName: string, headOfDepartment?: string) => {
    refetch();
    handleClosePopup();
  };
  const handleToggleTree = () => {
    setIsTreeExpanded(prevState => !prevState);
  };

  const transformToTree = (departments: Department[]): Department[] => {
    const map = new Map<number, Department>();
    const roots: Department[] = [];

    departments.forEach(department => {
      map.set(department.id, { ...department, children: [] });
    });

    map.forEach(department => {
      if (department.parent && department.parent.id) {
        const parent = map.get(department.parent.id);
        if (parent) {
          parent.children!.push(department);
        }
      } else {
        roots.push(department);
      }
    });

    return roots;
  };
  const renderTree = (departments: Department[], level: number = 0) => {
    // Sort departments by the number of children they have
    const sortedDepartments = [...departments].sort((a, b) => {
      const aChildrenCount = a.children ? a.children.length : 0;
      const bChildrenCount = b.children ? b.children.length : 0;
      return bChildrenCount - aChildrenCount;
    });
  
    return sortedDepartments.map(department => (
      <div key={department.id} style={{ paddingLeft: `${level * 20}px` }}>
        <ListItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {department.children && department.children.length > 0 ? (
              <Button onClick={() => handleToggle(department.id)} style={{ marginRight: '20px' }}>
                {expanded[department.id] ? <LuChevronDown /> : <LuChevronRight />}
              </Button>
            ) : (
              <div style={{ width: '20px', marginRight: '12px' }}></div>
            )}
            <span>{department.name}</span>
          </div>
  
          <Actions>
            {department.head_of_department && (
              <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', color: 'blue' }}>
                <LuUser style={{ marginRight: '5px' }} />
                {department.head_of_department.fullname}
              </div>
            )}
            <Button onClick={() => handleUpdate(department)}><LuFileEdit size={16} /></Button>
            <Button onClick={() => handleDelete(department.id)}><LuTrash size={16} /></Button>
            <Button onClick={() => handleAdd({ id: department.id, name: department.name })}><LuPlus size={16} /></Button>
          </Actions>
        </ListItem>
        {expanded[department.id] && department.children && renderTree(department.children, level + 1)}
      </div>
    ));
  };
     
  const name = userCompany?.name || formatMessage({ id: 'company', defaultMessage: 'Company' });

  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'department.errorLoadingDepartments', defaultMessage: 'Error loading departments' })}</div>;

  const departmentsTree = transformToTree(departmentsData?.data || []);

  return (
    <ListContainer>
      <ul>
        <ListItem style={{ paddingLeft: '0px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handleToggleTree} style={{marginRight: 20}}>
              {isTreeExpanded ? <LuChevronDown /> : <LuChevronRight />}
            </Button>
            <span>{name}</span>
          </div>
          <Actions>
            <Button onClick={() => handleAdd({ id: null, name: name })}><LuPlus size={16} /></Button>
          </Actions>
        </ListItem>
        {isTreeExpanded && (
          <div style={{ paddingLeft: '20px' }}>
            {renderTree(departmentsTree)}
          </div>
        )}
      </ul>
      {isPopupOpen && (
        <DepartmentPopup
          parentDepartment={parentDepartment}
          setParentDepartment={setParentDepartment}
          department={selectedDepartment}
          mode={mode}
          onClose={handleClosePopup}
          onPublish={handlePublish}
        />
      )}
    </ListContainer>
  );
};